<template>
 <SuccessBanner
  :key="key"
  :msg="successMsg"
  :active="activeBanner"
  @closeBanner="activeBanner = false"
  :isSuccess="isSuccess"
 />
 <PopupAlert
  ref="popupAlert"
  @go-to-ref="elementRef = bounceButton"
  @activeBanner="(activeBanner = true), setCollectData(), resetUnsavedChangesValue(), (key = !key)"
  @successMsg="successMsg = $event"
  @success="isSuccess = $event"
 />

 <div v-if="this.errorStatus === 403">
  <Error404 :status="this.errorStatus" :msg="this.errorStatusText" />
 </div>

 <div
  v-else-if="
   !adminToken &&
   (collectLock ||
    collectStatus === 'submitted' ||
    collectStatus === 'ready' ||
    collectStatus === 'closed')
  "
 >
  <CollectLock
   :collectId="customerFileId"
   :locked_at="collectStatus === 'submitted' ? collectUpdatedAt : collectLockedAt"
   :isSubmitted="collectStatus === 'submitted' ? true : false"
   :collectStatus="collectStatus"
   @refresh="refreshWindow()"
  />
 </div>

 <div
  v-else-if="adminToken && (collectLock || collectStatus === 'closed' || collectStatus === 'ready')"
 >
  <CollectLock
   :collectId="customerFileId"
   :locked_at="collectStatus === 'submitted' ? collectUpdatedAt : collectLockedAt"
   :isSubmitted="collectStatus === 'submitted' ? true : false"
   :collectStatus="collectStatus"
   @refresh="refreshWindow()"
  />
 </div>

 <div v-else-if="Object.keys(this.contentData).length === 0 && showIntroduction">
  <IntroductionCollect :collectType="collectType" @close-intro="this.showIntroduction = $event" />
 </div>

 <div v-else>
  <div class="relative bg-red-400 h-10" v-show="goBackToDependentStep">
   <div class="max-w-3xl mx-auto py-2 px-3 sm:px-6 lg:px-8">
    <div class="pr-16 sm:text-center sm:px-16">
     <p class="font-medium text-white">
      <span class="hidden md:inline">
       {{ $t("goBackToDependentStep") }}
       <span class="font-bold">{{ $t(toBeCompleted) }} </span>.
      </span>
     </p>
    </div>
    <div
     class="absolute inset-y-0 right-0 pt-1 pr-1 flex items-start sm:pt-0 sm:pr-2 sm:items-start"
    >
     <button
      @click="goBackToDependentStep = false"
      type="button"
      class="flex p-2 rounded-md hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-white"
     >
      <span class="sr-only">Dismiss</span>
      <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
     </button>
    </div>
   </div>
  </div>

  <nav aria-label="Progress" class="m-5">
   <ol
    role="list"
    class="bg-white border border-gray-300 rounded-md divide-y divide-gray-300 2xl:flex 2xl:divide-y-0"
   >
    <li v-for="(step, stepIdx) in steps" :key="step.name" class="relative md:flex-1 md:flex">
     <a
      @click="this.breadcrumbs(stepIdx)"
      v-if="step.complete && !step.current && !step.needCompletion"
      class="group flex items-center w-full cursor-pointer"
     >
      <span class="px-6 py-2 flex items-center text-sm font-medium">
       <span
        class="flex-shrink-0 w-8 h-8 flex items-center justify-center body__header--button rounded-full"
       >
        <CheckIcon class="w-5 h-5 text-white" aria-hidden="true" />
       </span>
       <span class="ml-4 text-sm font-medium text-gray-900">
        {{ $t(step.name) }}
       </span>
      </span>
     </a>
     <a
      @click="this.breadcrumbs(stepIdx)"
      v-if="step.complete && !step.current && step.needCompletion"
      class="group flex items-center w-full cursor-pointer"
     >
      <span class="px-6 py-2 flex items-center text-sm font-medium">
       <span
        class="flex-shrink-0 w-8 h-8 flex items-center justify-center body__header--button rounded-full"
       >
        <font-awesome-icon icon="hourglass" class="w-5 h-5 text-white" />
       </span>
       <span class="ml-4 text-sm font-medium text-gray-900">
        {{ $t(step.name) }}
       </span>
      </span>
     </a>
     <a
      @click="this.breadcrumbs(stepIdx)"
      v-if="step.current && !step.complete"
      class="px-6 py-2 flex items-center text-sm font-medium cursor-pointer"
      aria-current="step"
     >
      <span
       class="flex-shrink-0 w-8 h-8 flex items-center justify-center border-2 border-gray-500 bg-gray-300 rounded-full"
      >
       <span>{{ step.id }}</span>
      </span>
      <span class="ml-4 text-xs font-medium">
       {{ $t(step.name) }}
      </span>
     </a>
     <a
      @click="this.breadcrumbs(stepIdx)"
      v-if="step.complete && step.current && !step.needCompletion"
      class="group flex items-center w-full cursor-pointer"
     >
      <span class="px-6 py-2 flex items-center text-sm font-medium">
       <span
        class="flex-shrink-0 w-8 h-8 flex items-center justify-center bg-green-500 body__header--button_border border-2 border-green-700 rounded-full"
       >
        <CheckIcon class="w-5 h-5 text-white" aria-hidden="true" />
       </span>
       <span class="ml-4 text-sm font-medium text-gray-900">
        {{ $t(step.name) }}
       </span>
      </span>
     </a>
     <a
      @click="this.breadcrumbs(stepIdx)"
      v-if="step.complete && step.current && step.needCompletion"
      class="group flex items-center w-full cursor-pointer"
     >
      <span class="px-6 py-2 flex items-center text-sm font-medium">
       <span
        class="flex-shrink-0 w-8 h-8 flex items-center justify-center bg-green-500 body__header--button_border border-2 border-green-700 rounded-full"
       >
        <font-awesome-icon icon="hourglass" class="w-5 h-5 text-white" />
       </span>
       <span class="ml-4 text-sm font-medium text-gray-900">
        {{ $t(step.name) }}
       </span>
      </span>
     </a>
     <a
      @click="this.breadcrumbs(stepIdx)"
      v-if="!step.current && !step.complete"
      class="group flex items-center cursor-pointer"
     >
      <span class="px-6 py-2 flex items-center text-sm font-medium">
       <span
        class="flex-shrink-0 w-8 h-8 flex items-center justify-center border-2 border-gray-300 rounded-full group-hover:border-gray-400"
       >
        <span class="text-gray-500 group-hover:text-gray-900">{{ step.id }}</span>
       </span>
       <span class="ml-4 text-xs font-medium text-gray-500 group-hover:text-gray-900">
        {{ $t(step.name) }}
       </span>
      </span>
     </a>

     <template
      v-if="
       stepIdx !== steps.length - 1 && step.id !== '04' && step.id !== '05' && step.id !== '06'
      "
     >
      <!-- Arrow separator for lg screens and up -->
      <div class="hidden md:block absolute top-0 right-0 h-full w-5" aria-hidden="true">
       <svg
        :class="[step.current ? 'text-green-600' : 'text-gray-300', 'h-full w-full ']"
        viewBox="0 0 22 80"
        fill="none"
        preserveAspectRatio="none"
       >
        <path
         d="M0 -2L20 40L0 82"
         vector-effect="non-scaling-stroke"
         stroke="currentcolor"
         stroke-linejoin="round"
        />
       </svg>
      </div>
     </template>
    </li>
   </ol>
  </nav>

  <CollectMainInfos
   v-if="navIdx === 0"
   :contactsLoaded="contactsLoaded"
   :domain3cx="domain3cx"
   :dependenciesToBeCleared="dependenciesToBeCleared"
   :dependencies="dependencies"
   :contentData="contentData"
   :key="this.contentChange && formIndex"
   :fileId="this.fileId"
   :customerFileId="customerFileId"
   :savedForm="savedForms.mainInfos"
   :bounceButton="bounceButton"
   :unsavedChangesBool="unsavedChangesBool"
   :elementRef="elementRef"
   @scroll-to-top="this.scrollToTop()"
   @activeBanner="
    (activeBanner = true),
     (key = !key),
     (isEdited = false),
     resetUnsavedChangesValue(),
     this.setCollectData()
   "
   @successMsg="successMsg = $event"
   @success="isSuccess = $event"
   @unsaved-changes="(...args) => this.assignUnsavedChangesValue(...args)"
  />

  <CollectNumbers
   v-if="navIdx === 1"
   :dependenciesToBeCleared="dependenciesToBeCleared"
   :dependencies="dependencies"
   :contentData="contentData"
   :key="this.contentChange && formIndex"
   :fileId="this.fileId"
   :customerFileId="customerFileId"
   :savedForm="savedForms.numbers"
   :bounceButton="bounceButton"
   :unsavedChangesBool="unsavedChangesBool"
   :elementRef="elementRef"
   @activeBanner="
    (activeBanner = true),
     (key = !key),
     (isEdited = false),
     resetUnsavedChangesValue(),
     this.setCollectData(),
     this.scrollToTop()
   "
   @successMsg="successMsg = $event"
   @success="isSuccess = $event"
   @unsaved-changes="(...args) => this.assignUnsavedChangesValue(...args)"
   @sda-town="sdaTown = $event"
  />
  <CollectUsers
   v-if="navIdx === 2"
   :dependenciesToBeCleared="dependenciesToBeCleared"
   :collectType="collectType"
   :dependencies="dependencies"
   :contentData="contentData"
   :key="this.contentChange && formIndex"
   :fileId="this.fileId"
   :sdaTown="filteredSdaTown()"
   :navIdx="navIdx"
   :phoneData="phoneData"
   :companyAddress="companyAddress"
   :customerFileId="customerFileId"
   :createdSda="createdSda"
   :search="search"
   :users="users"
   :queues="queues"
   :svi="svi"
   :bounceButton="bounceButton"
   :unsavedChangesBool="unsavedChangesBool"
   :elementRef="elementRef"
   @activeBanner="
    (activeBanner = true),
     (key = !key),
     (isEdited = false),
     resetUnsavedChangesValue(),
     this.setCollectData(),
     this.scrollToTop()
   "
   @successMsg="successMsg = $event"
   @success="isSuccess = $event"
   @unsaved-changes="(...args) => this.assignUnsavedChangesValue(...args)"
   @update-sda-town="updateSdaTown($event)"
   @remove-sda-town="(...args) => removeSdaTown(...args)"
  />
  <StudioMessageForm
   v-if="navIdx === 3"
   :dependenciesToBeCleared="dependenciesToBeCleared"
   :dependencies="dependencies"
   :contentData="contentData"
   :key="this.contentChange && formIndex"
   :fileId="this.fileId"
   :customerFileId="customerFileId"
   :createdSda="createdSda"
   :sdaTown="filteredSdaTown()"
   :queues="queues"
   :users="users"
   :svi="svi"
   :search="search"
   :bounceButton="bounceButton"
   :unsavedChangesBool="unsavedChangesBool"
   :elementRef="elementRef"
   @activeBanner="
    (activeBanner = true),
     (key = !key),
     (isEdited = false),
     resetUnsavedChangesValue(),
     this.setCollectData(),
     this.scrollToTop()
   "
   @successMsg="successMsg = $event"
   @success="isSuccess = $event"
   @unsaved-changes="(...args) => this.assignUnsavedChangesValue(...args)"
  />
  <CollectCallQueueForm
   v-if="navIdx === 4"
   :dependenciesToBeCleared="dependenciesToBeCleared"
   :collectType="collectType"
   :dependencies="dependencies"
   :contentData="contentData"
   :key="this.contentChange && formIndex"
   :fileId="this.fileId"
   :customerFileId="customerFileId"
   :users="users"
   :queues="queues"
   :svi="svi"
   :sdaTown="filteredSdaTown()"
   :sdaLeft="sdaLeft"
   :createdSda="createdSda"
   :search="search"
   :bounceButton="bounceButton"
   :unsavedChangesBool="unsavedChangesBool"
   :elementRef="elementRef"
   @activeBanner="
    (activeBanner = true),
     (key = !key),
     (isEdited = false),
     resetUnsavedChangesValue(),
     this.setCollectData(),
     this.scrollToTop()
   "
   @successMsg="successMsg = $event"
   @success="isSuccess = $event"
   @unsaved-changes="(...args) => this.assignUnsavedChangesValue(...args)"
   @update-sda-town="updateSdaTown($event)"
   @remove-sda-town="(...args) => removeSdaTown(...args)"
  />
  <SVIForm
   v-if="navIdx === 5"
   :dependenciesToBeCleared="dependenciesToBeCleared"
   :collectType="collectType"
   :dependencies="dependencies"
   :contentData="contentData"
   :key="this.contentChange && formIndex"
   :fileId="this.fileId"
   :customerFileId="customerFileId"
   :createdSda="createdSda"
   :users="users"
   :queues="queues"
   :svi="svi"
   :sdaTown="filteredSdaTown()"
   :sdaLeft="sdaLeft"
   :navIdx="navIdx"
   :search="search"
   :bounceButton="bounceButton"
   :unsavedChangesBool="unsavedChangesBool"
   :elementRef="elementRef"
   @activeBanner="
    (activeBanner = true),
     (key = !key),
     (isEdited = false),
     resetUnsavedChangesValue(),
     this.setCollectData(),
     this.scrollToTop()
   "
   @successMsg="successMsg = $event"
   @success="isSuccess = $event"
   @unsaved-changes="(...args) => this.assignUnsavedChangesValue(...args)"
   @update-sda-town="updateSdaTown($event)"
   @remove-sda-town="(...args) => removeSdaTown(...args)"
  />

  <CollectGroups
   v-if="navIdx === 6"
   :dependenciesToBeCleared="dependenciesToBeCleared"
   :dependencies="dependencies"
   :contentData="contentData"
   :key="this.contentChange && formIndex"
   :fileId="this.fileId"
   :customerFileId="customerFileId"
   :collectType="collectType"
   :userData="users"
   :search="search"
   :bounceButton="bounceButton"
   :unsavedChangesBool="unsavedChangesBool"
   :elementRef="elementRef"
   @activeBanner="
    (activeBanner = true),
     (key = !key),
     (isEdited = false),
     resetUnsavedChangesValue(),
     this.setCollectData(),
     this.scrollToTop()
   "
   @successMsg="successMsg = $event"
   @success="isSuccess = $event"
   @unsaved-changes="(...args) => this.assignUnsavedChangesValue(...args)"
  />
  <CollectNetworkForm
   v-if="navIdx === 7"
   :dependenciesToBeCleared="dependenciesToBeCleared"
   :dependencies="dependencies"
   :contentData="contentData"
   :key="this.contentChange && formIndex"
   :fileId="this.fileId"
   :customerFileId="customerFileId"
   :bounceButton="bounceButton"
   :unsavedChangesBool="unsavedChangesBool"
   :elementRef="elementRef"
   @activeBanner="
    (activeBanner = true),
     (key = !key),
     (isEdited = false),
     resetUnsavedChangesValue(),
     this.setCollectData(),
     this.scrollToTop()
   "
   @successMsg="successMsg = $event"
   @success="isSuccess = $event"
   @unsaved-changes="(...args) => this.assignUnsavedChangesValue(...args)"
  />
  <CustomerRelationsForm
   v-if="navIdx === 8"
   :dependenciesToBeCleared="dependenciesToBeCleared"
   :dependencies="dependencies"
   :contentData="contentData"
   :key="this.contentChange && formIndex"
   :fileId="this.fileId"
   :customerFileId="customerFileId"
   :bounceButton="bounceButton"
   :unsavedChangesBool="unsavedChangesBool"
   :elementRef="elementRef"
   @activeBanner="
    (activeBanner = true),
     (key = !key),
     (isEdited = false),
     resetUnsavedChangesValue(),
     this.setCollectData(),
     this.scrollToTop()
   "
   @successMsg="successMsg = $event"
   @success="isSuccess = $event"
   @unsaved-changes="(...args) => this.assignUnsavedChangesValue(...args)"
  />
  <CollectLegalNotice
   v-if="navIdx === 9"
   :dependenciesToBeCleared="dependenciesToBeCleared"
   :dependencies="dependencies"
   :contentData="contentData"
   :key="this.contentChange && formIndex"
   :fileId="this.fileId"
   :customerFileId="customerFileId"
   :steps="steps"
   @activeBanner="(activeBanner = true), (key = !key), (isEdited = false), this.setCollectData()"
   @successMsg="successMsg = $event"
   @success="isSuccess = $event"
   @goBackToDependentStep="goBackToDependentStep = $event"
  />
 </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

import collectDependenciesKeys from "./CollectKeysDependencies";
import IntroductionCollect from "../../components/Collect/IntroductionCollect.vue";
import Error404 from "../../components/Error404.vue";

import CollectLock from "../../components/Collect/CollectLock.vue";
import PopupAlert from "../../components/Collect/PopupAlert.vue";
import CollectNetworkForm from "../../components/Collect/CollectNetworkForm.vue";
import CollectCallQueueForm from "../../components/Collect/CollectCallQueueForm.vue";
import SVIForm from "../../components/Collect/SVIForm.vue";
import StudioMessageForm from "../../components/Collect/StudioMessageForm.vue";
import CustomerRelationsForm from "../../components/Collect/CustomerRelationsForm.vue";
import CollectMainInfos from "../../components/Collect/CollectMainInfos.vue";
import CollectNumbers from "../../components/Collect/CollectNumbers.vue";
import CollectUsers from "../../components/Collect/CollectUsers.vue";
import CollectGroups from "../../components/Collect/CollectGroups.vue";
import CollectLegalNotice from "../../components/Collect/CollectLegalNotice.vue";
import SuccessBanner from "../../components/SuccessBanner.vue";
import {
 Listbox,
 ListboxButton,
 ListboxLabel,
 ListboxOption,
 ListboxOptions,
 Switch,
 SwitchDescription,
 SwitchGroup,
 SwitchLabel,
} from "@headlessui/vue";
import {
 CheckIcon,
 SelectorIcon,
 PlusSmIcon,
 MinusSmIcon,
 TrashIcon,
 XIcon,
} from "@heroicons/vue/solid";
import { xmlExtensionFile } from "../../views/Collect/xmlFiles";

const steps = [
 {
  id: "01",
  name: "mainInfos",
  current: false,
  needCompletion: false,
  complete: false,
  objName: "mainInfos",
 },
 {
  id: "02",
  name: "numbersPortability",
  current: false,
  needCompletion: false,
  complete: false,
  objName: "numbers",
 },
 {
  id: "03",
  name: "usersTitle",
  current: false,
  needCompletion: false,
  complete: false,
  objName: "users",
 },
 {
  id: "04",
  name: "studioMessage",
  current: false,
  needCompletion: false,
  complete: false,
  objName: "studio",
 },
 {
  id: "05",
  name: "callQueue",
  current: false,
  needCompletion: false,
  complete: false,
  objName: "callQueue",
 },
 {
  id: "06",
  name: "SVI",
  current: false,
  needCompletion: false,
  complete: false,
  objName: "svi",
 },
 {
  id: "07",
  name: "groups",
  current: false,
  needCompletion: false,
  complete: false,
  objName: "groups",
 },
 {
  id: "08",
  name: "siteAndNetwork",
  current: false,
  needCompletion: false,
  complete: false,
  objName: "network",
 },
 {
  id: "09",
  name: "customerRelations",
  current: false,
  needCompletion: false,
  complete: false,
  objName: "relations",
 },
 {
  id: "10",
  name: "endCollect",
  current: false,
  needCompletion: false,
  complete: false,
  objName: "legalNotice",
 },
];

const dependencies = {
 mainInfos: [],
 numbers: ["mainInfos"],
 users: ["mainInfos", "numbers"],
 callQueue: ["mainInfos", "numbers", "users"],
 svi: ["mainInfos", "numbers", "users"],
 studio: ["mainInfos", "numbers", "users"],
 groups: ["mainInfos", "numbers", "users"],
 network: [],
 relations: [],
 legalNotice: ["mainInfos", "numbers", "users", "network", "relations"],
};

const account = localStorage.getItem("account");

const companyAddress = [];

const users = [];
const queues = [];
const svi = [];

export default {
 props: ["fileId", "formIndex", "customerFileId", "search"],
 name: "Collect",
 components: {
  CheckIcon,
  CollectLock,
  CollectMainInfos,
  CollectNumbers,
  CollectUsers,
  CollectGroups,
  CollectLegalNotice,
  CollectCallQueueForm,
  CollectNetworkForm,
  CustomerRelationsForm,
  Error404,
  IntroductionCollect,
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
  MinusSmIcon,
  PlusSmIcon,
  PopupAlert,
  Switch,
  SwitchDescription,
  SwitchGroup,
  SwitchLabel,
  SelectorIcon,
  StudioMessageForm,
  SVIForm,
  SuccessBanner,
  TrashIcon,
  XIcon,
 },
 setup() {
  return {
   steps,
  };
 },
 data() {
  return {
   contactsLoaded: [],
   collectLock: false,
   collectLockedAt: "",
   collectUpdatedAt: "",
   collectLockedId: "",
   collectStatus: "",
   collectType: "",
   account,
   activeBanner: false,
   bounceButton: "",
   bounceButtonVal: "",
   collectData: undefined,
   companyAddress,
   createdSda: [],
   contentChange: true,
   contentData: {},
   dependencies,
   data: {},
   domain3cx: [],
   elementRef: "",
   goBackToDependentStep: false,
   isSuccess: false,
   isEdited: false,
   key: false,
   leaveWithoutSave: false,
   navIdx: undefined,
   phoneData: {},
   queues,
   showIntroduction: true,
   successMsg: "",
   sdaTown: [],
   sdaMax: null,
   sdaLeft: null,
   showForm: false,
   savedForms: {
    mainInfos: {},
    numbers: {},
    users: [{}],
    callQueue: [],
    svi: {},
    studio: {},
    groups: [{}],
    network: {},
    relations: {},
    legalNotice: {},
   },
   svi,
   toBeCompleted: "",
   users,
   userIdx: null,
   unsavedChangesBool: false,
   unsavedForms: {
    mainInfos: {},
    numbers: {},
    users: [],
    callQueue: [],
    svi: {},
    studio: {},
    groups: [],
    network: {},
    relations: {},
    legalNotice: {},
   },
  };
 },
 methods: {
  setStatus(idx) {
   if (idx) {
    this.steps.forEach((res, index) => {
     index == idx ? (res.current = true) : (res.current = false);
    });
   } else {
    this.steps.forEach((res, index) => {
     index == this.formIndex ? (res.current = true) : (res.current = false);
    });
   }
  },
  setComplete(content) {
   let keys = Object.keys(content);
   this.steps.forEach((res) => {
    keys.includes(res.objName) &&
    (content[res.objName].length > 0 || Object.keys(content[res.objName]).length > 0)
     ? (res.complete = true)
     : (res.complete = false);

    if (content["users"]) {
     if (!content["users"].unconfiguredUsersOutcome && content["users"].users.length > 0) {
      res.name === "usersTitle" ? (res.needCompletion = true) : "";
     } else if (!content["users"].unconfiguredUsersOutcome || content["users"].users.length < 1) {
      res.name === "usersTitle" ? (res.complete = false) : "";
     } else {
      res.name === "usersTitle" ? (res.needCompletion = false) : "";
     }
    }

    if (content[res.objName] && content[res.objName].length > 0) {
     for (let index = 0; index < content[res.objName].length; index++) {
      const element = content[res.objName][index];
      if (element.isComplete) {
       res.needCompletion = false;
      } else {
       res.needCompletion = true;
      }
     }
    }
   });
  },
  setSdaCreated(arr) {
   let mappedArr = [...this.createdSda, ...arr];
   this.createdSda = [...new Set(mappedArr)];
  },
  assignUnsavedChangesValue(...args) {
   this.unsavedChangesBool = args[0];
   this.bounceButtonVal = args[1];
   !this.unsavedChangesBool ? (this.bounceButton = args[1]) : "";
  },
  resetUnsavedChangesValue() {
   this.unsavedChangesBool = false;
   this.bounceButtonVal = "";
   this.bounceButton = "";
   this.elementRef = "";
  },
  breadcrumbs(idx) {
   if (this.unsavedChangesBool) {
    this.bounceButton = this.bounceButtonVal;
    this.$refs.popupAlert.show({
     title: "unsavedChangesTitle",
     message: "unsavedChangesWarningMessage",
     messageOption: "unsavedChangesMessageOption",
     okButton: "unsavedChangesCancelButton",
     cancelButton: "cancel",
     data: "",
     idx: idx,
     fileId: this.fileId,
     customerFileId: this.customerFileId,
     objName: "",
     contentData: "",
     isEdited: "",
     userIdx: "",
    });
   } else {
    if (!this.isEdited) {
     this.saveFormPopUp(idx);
    } else {
     this.editFormPopUp(idx);
    }
   }
  },
  saveFormPopUp(idx) {
   this.resetUnsavedChangesValue();
   this.$router.push(`/customer-files/collect/${this.customerFileId}/${this.fileId}/${idx}`);
   this.$forceUpdate();
  },
  editFormPopUp(idx) {
   this.resetUnsavedChangesValue();
   this.$router.push(`/customer-files/collect/${this.customerFileId}/${this.fileId}/${idx}`);
   this.$forceUpdate();
   this.isEdited = false;
  },
  stepActive() {
   const found = steps.findIndex((el) => el.status === "current");
   this.navIdx = found;
  },
  setDataForms(data) {
   //Main Infos
   if (data && Object.keys(data).length > 0) {
    this.savedForms.mainInfos = JSON.stringify(data.mainInfos);
    this.unsavedForms.mainInfos = data.mainInfos;
    //Numbers & Portability
    this.savedForms.numbers = JSON.stringify(data.numbers);
    this.unsavedForms.numbers = data.numbers;
    //Users
    this.savedForms.users = JSON.stringify(data.users);
    this.unsavedForms.users = data.users;

    //Call Queue
    this.savedForms.callQueue = JSON.stringify(data.callQueue);
    this.unsavedForms.callQueue = data.callQueue;
    //SVI
    this.savedForms.svi = JSON.stringify(data.svi);
    this.unsavedForms.svi = data.svi;
    //Studio Message
    this.savedForms.studio = JSON.stringify(data.studio);
    this.unsavedForms.studio = data.studio;
    //Groups
    this.savedForms.groups = JSON.stringify(data.groups);
    this.unsavedForms.groups = data.groups;
    //Site and Network
    this.savedForms.network = JSON.stringify(data.network);
    this.unsavedForms.network = data.network;
    //Customer Relations
    this.savedForms.relations = JSON.stringify(data.relations);
    this.unsavedForms.relations = data.relations;
    //Customer Relations
    this.savedForms.legalNotice = JSON.stringify(data.legalNotice);
    this.unsavedForms.legalNotice = data.legalNotice;
   }
  },
  async getPhoneData() {
   try {
    const res = await axios.get(
     `${this.$cookie.getCookie("API")}/api/v1/customerFiles/${this.customerFileId}/phones`,
     {
      params: {
       customerAccount: this.account,
      },
      headers: {
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
     }
    );

    this.phoneData = res.data;
   } catch (error) {
    this.errorHandling(error);
   }
  },
  async getLoadedContact() {
   try {
    const res = await axios.get(
     `${this.$cookie.getCookie("API")}/api/v1/admin-contacts?customerAccount=${this.account}`
    );
    this.contactsLoaded = res.data;
   } catch (error) {
    console.error(error);
   }
   this.$emit("setLoading", false);
  },
  async getDomains3cx() {
   try {
    const res = await axios.get(
     `${this.$cookie.getCookie("API")}/api/v1/informationCollections/domains-3cx `
    );

    this.domain3cx = res.data;
   } catch (error) {
    console.error(error);
   }
   this.$emit("setLoading", false);
  },

  async isCollectLocked() {
   try {
    const res = await axios.get(
     `${this.$cookie.getCookie("API")}/api/v1/informationCollections/${
      this.fileId
     }?customerAccount=${this.account}`
    );
    this.collectLockedAt = res.data.locked_at;

    this.collectLock = false;
    if (!res.data.locked_at) {
     this.collectLock = false;
     this.setCollect();
     this.lockCollect();
    } else if (res.data.locked_at && localStorage.getItem("locked_id") !== res.data.locked_id) {
     this.collectLock = true;
    } else {
     this.refreshLockTime(res.data.locked_id);
    }
   } catch (error) {
    this.errorHandling(error);
    this.activeBanner = true;
    this.success = false;
    this.successMsg = this.errorStatusText;
    this.key = !this.key;
   }
  },

  async mountData() {
   try {
    const res = await axios.get(
     `${this.$cookie.getCookie("API")}/api/v1/informationCollections/${
      this.fileId
     }?customerAccount=${this.account}`
    );

    this.collectLock = false;
    this.collectLockedAt = res.data.locked_at;
    this.collectUpdatedAt = res.data.updated_at;
    this.collectStatus = res.data.status;
    if (!res.data.locked_at) {
     this.setCollect();
     this.lockCollect();
    } else if (res.data.locked_at && localStorage.getItem("locked_id") === res.data.locked_id) {
     this.setCollect();
    } else {
     this.collectLock = true;
    }
   } catch (error) {
    this.errorHandling(error);
    this.activeBanner = true;
    this.success = false;
    this.successMsg = this.errorStatusText;
    this.key = !this.key;
   }
  },
  setCollect() {
   this.navIdx = parseInt(this.formIndex);
   this.setStatus();
   this.getPhoneData();
   this.resetUnsavedChangesValue();

   if (parseInt(this.formIndex) >= 0 && parseInt(this.formIndex) < this.steps.length) {
    this.setCollectData();
   }
  },
  async lockCollect() {
   const options = {
    method: "POST",
    url: `${this.$cookie.getCookie("API")}/api/v1/informationCollections/${
     this.fileId
    }/lock?customerAccount=${this.account}`,
   };
   axios
    .request(options)
    .then((res) => {
     localStorage.setItem("locked_id", res.data.locked_id);
    })
    .catch((err) => {
     console.error(err);

     this.$emit("activeBanner");
     this.$emit("success", false);
     this.$emit("successMsg", err.response.data.message);
    });
  },
  async refreshLockTime(id) {
   const options = {
    method: "POST",
    url: `${this.$cookie.getCookie("API")}/api/v1/informationCollections/${
     this.fileId
    }/lock?customerAccount=${this.account}&locked_id=${id}`,
   };
   axios
    .request(options)
    .then((res) => {})
    .catch((err) => {
     console.error(err);
     this.$emit("activeBanner");
     this.$emit("success", false);
     this.$emit("successMsg", err.response.data.message);
    });
  },
  async getCollect() {
   try {
    const res = await axios.get(
     `${this.$cookie.getCookie("API")}/api/v1/informationCollections/${
      this.fileId
     }?customerAccount=${this.account}`
    );
    this.collectData = res.data.content;
    this.collectType = res.data.type;
    return res.data;
   } catch (error) {
    this.errorHandling(error);
    this.activeBanner = true;
    this.success = false;
    this.successMsg = this.errorStatusText;
    this.key = !this.key;

    /*    error.response.status === 403
          ? this.$router.push(
              `/customer-files/collectList/${this.customerFileId}`
            )
          : ""; */
   }
  },
  async setCollectData() {
   var regExp = /[a-zA-Z]/g;
   let usersObj = [];
   let addressKeys = [];
   let addressObj = {};
   let queuesObj = [];
   let sviObj = {};
   this.createdSda = [];
   await this.getCollect()
    .then((data) => {
     data.content ? (this.contentData = JSON.parse(data.content)) : (this.contentData = {});
     let dataContent = this.contentData;

     if (this.contentData.mainInfos?.address) {
      // Temporary method to convert data structure of formFinal.address
      if (typeof this.contentData.mainInfos.address === "object") {
       addressKeys = Object.keys(this.contentData.mainInfos.address);
       addressObj = this.contentData.mainInfos.address;
       addressKeys.forEach((element, index) => {
        this.companyAddress.splice(index, 1, addressObj[element].name);
       });
      } else {
       this.contentData.mainInfos.address.forEach((address, index) => {
        this.companyAddress.splice(index, 1, address.name);
       });
      }
     }

     if (this.contentData.numbers) {
      let form = this.contentData.numbers;
      let sdaObj = {};
      let sdaKeys = [];
      var townList = [];

      //Calcul the sum of SDA chosen by the user
      sdaKeys = Object.keys(form.sda.sda);
      sdaObj = form.sda.sda;
      const obj = this.getNumberIdentifierObject(sdaObj);
      let data = {
       sites: obj,
       flatten: false,
      };

      if (Object.keys(obj).length > 0) this.createNumbers(data);
      /*  sdaKeys.forEach((element) => {
       for (let index = 0; index < sdaObj[element].number; index++) {
        let randomNumber = sdaObj[element].numberIdentifier;
        let newNumber = sdaObj[element].town + " - " + randomNumber;

        townList.push(newNumber);
        this.sdaTown = [...townList];
       }
      }); */
     }
     if (
      this.contentData.users &&
      this.contentData.users.users &&
      this.contentData.users.users.length > 0
     ) {
      usersObj = this.contentData.users.users;
      usersObj.forEach((element, index) => {
       var userNumber = element.userSettings.internalNumber;
       var userFullName = element.userSettings.firstName + " " + element.userSettings.lastName;
       var userInfos = userNumber + " - " + userFullName;
       this.users.splice(index, 1, userInfos);

       let sda = [...element.userSettings.sdaNumber];
       sda.forEach((el, index) => {
        String(sda[index]).length < 10 && !regExp.test(sda[index])
         ? (sda[index] = "0" + el)
         : String(sda[index]).length > 10 && !regExp.test(sda[index])
         ? (sda[index] = "0" + String(el).slice(-9))
         : el;
       });

       this.createdSda = [...this.createdSda, ...sda];
      });
     }
     if (this.contentData.callQueue && Object.keys(this.contentData.callQueue).length > 0) {
      //Temporary method to convert global data structure of callQueue step
      if (Array.isArray(this.contentData.callQueue)) {
       this.contentData.callQueue = { otherComment: "", data: this.contentData.callQueue };
      }
      queuesObj = this.contentData.callQueue.data;
      queuesObj.forEach((element, index) => {
       this.queues.splice(index, 1, element.name);
       let sda = [...element.associateNumber];
       sda.forEach((el, index) => {
        sda[index].length < 10 && !regExp.test(sda[index])
         ? (sda[index] = "0" + el)
         : sda[index].length > 10 && !regExp.test(sda[index])
         ? (sda[index] = "0" + String(el).slice(-9))
         : el;
       });
       this.createdSda = [...this.createdSda, ...sda];
      });
     }
     if (this.contentData.svi && Object.keys(this.contentData.svi).length > 0) {
      //Temporary method to convert global data structure of SVI step
      if (Array.isArray(this.contentData.svi)) {
       this.contentData.svi = { otherComment: "", data: this.contentData.svi };
      }
      sviObj = this.contentData.svi.data;
      sviObj.forEach((element, index) => {
       this.svi.splice(index, 1, element.sviName);
       let sda = [...element.associateNumber];
       sda.forEach((el, index) => {
        sda[index].length < 10 && !regExp.test(sda[index])
         ? (sda[index] = "0" + el)
         : sda[index].length > 10 && !regExp.test(sda[index])
         ? (sda[index] = "0" + String(el).slice(-9))
         : el;
       });
       this.createdSda = [...this.createdSda, ...sda];
      });
     }
     if (this.contentData.numbers && this.contentData.numbers.sdaUsed) {
      this.sdaLeft = this.contentData.numbers.sdaUsed.sdaLeft;
      this.sdaMax = this.contentData.numbers.sdaUsed.sdaMax;
     }

     this.contentChange = !this.contentChange;
     this.setSdaCreated(this.createdSda);
     this.setComplete(this.contentData);
     this.setDataForms(this.contentData);
     this.checkForDependencies(dataContent, parseInt(this.formIndex));
     //this.setCollectAsActive(JSON.stringify(this.contentData));
    })
    .catch((error) => {
     console.error(error);

     this.activeBanner = true;
     this.success = false;
    });
   // if (data.status !== "active") {
   //   this.setCollectAsActive(JSON.stringify(dataContent));
   // }
  },

  checkForDependencies(content, index) {
   let missingSteps = [];
   let stepName = undefined;
   let stepDependencies = undefined;
   let contentKeys = [];
   let callQueueComplete = true;

   let contentKey = Object.keys(content).find((key) => {
    if (content[key].length > 0 || Object.keys(content[key]).length > 0) {
     contentKeys.push(key);
    }
   });

   let goBackIndex = undefined;
   stepName = this.steps[index].objName;
   if (stepName) {
    // if (stepName === "legalNotice") {

    // }
    stepDependencies = this.dependencies[stepName];
   }

   if (stepDependencies && stepDependencies.length > 0) {
    if (
     stepDependencies.includes("callQueue") &&
     this.contentData.callQueue &&
     this.contentData.callQueue.length > 0
    ) {
     for (let index = 0; index < this.contentData.callQueue.length; index++) {
      const element = this.contentData.callQueue[index];
      // if (!element.isComplete) {
      //   missingSteps.push("callQueue");
      // }
     }
    }

    for (var i = 0; i < stepDependencies.length; i += 1) {
     if (
      contentKeys.indexOf(stepDependencies[i]) < 0 &&
      !missingSteps.includes(stepDependencies[i])
     ) {
      missingSteps.push(stepDependencies[i]);
     }
    }
    if (missingSteps[0]) {
     let stepToBack = this.steps.filter((res) => {
      return res.objName === missingSteps[0];
     });
     goBackIndex = parseInt(stepToBack[0].id) - 1;
     this.toBeCompleted = stepToBack[0].name;
    }
   } else {
    this.navIdx = index;
   }
   if (goBackIndex >= 0) {
    this.$router.push(
     `/customer-files/collect/${this.customerFileId}/${this.fileId}/${goBackIndex}`
    );
    this.goBackToDependentStep = true;
   } else {
    this.navIdx = index;
   }
  },
  dependenciesToBeCleared(objName) {
   let dataToBeCleared = {};
   let keys = Object.keys(collectDependenciesKeys);
   if (keys.indexOf(objName) >= 0) {
    dataToBeCleared = collectDependenciesKeys[objName];
   }
   // Object.keys(this.dependencies).find((key) => {
   //   if (this.dependencies[key].includes(objName) && key !== "legalNotice") {
   //     dataToBeCleared.push(key);
   //   }
   // });

   return dataToBeCleared;
  },
  setCollectAsActive(content) {
   const options = {
    method: "PUT",
    url: `${this.$cookie.getCookie("API")}/api/v1/informationCollections/${
     this.fileId
    }?customerAccount=${this.account}`,
    data: {
     content: `${content}`,
     status: "active",
    },
    headers: {
     "Content-Type": "application/json",
     Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
   };
   axios
    .request(options)
    .then((res) => {
     console.log("file: Collect.vue ~ line 713 ~ axios.request ~ res", res);
    })
    .catch((error) => {
     console.error(error);
    });
  },
  async setCollectAsInactive() {
   let data = await this.getCollect();
   let content = data.content;
   let status = data.status;
   const options = {
    method: "PUT",
    url: `${this.$cookie.getCookie("API")}/api/v1/informationCollections/${
     this.fileId
    }?customerAccount=${this.account}`,
    data: {
     content: `${content}`,

     userId: "",
    },
    headers: {
     "Content-Type": "application/json",
     Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
   };
   axios
    .request(options)
    .then((res) => {})
    .catch(function (error) {
     this.errorHandling(error);
    });
  },
  OBJtoXML(obj) {
   var xml = "";
   for (var prop in obj) {
    if (prop === "Hours") {
     xml +=
      obj[prop] instanceof Array
       ? ""
       : "<" + prop + " " + "HoursType='OfficeHours' IgnoreHolidays='False'" + "/>";
    } else if (prop === "BreakTime") {
     xml +=
      obj[prop] instanceof Array
       ? ""
       : "<" + prop + " " + "HoursType='OfficeHours' IgnoreHolidays='False'" + "/>";
    } else {
     xml += obj[prop] instanceof Array ? "" : "<" + prop + ">";
     if (obj[prop] instanceof Array) {
      for (var array in obj[prop]) {
       xml += "<" + prop + ">";
       xml += this.OBJtoXML(new Object(obj[prop][array]));
       xml += "</" + prop + ">";
      }
     } else if (typeof obj[prop] == "object") {
      xml += this.OBJtoXML(new Object(obj[prop]));
     } else {
      xml += obj[prop];
     }
     xml += obj[prop] instanceof Array ? "" : "</" + prop + ">";
    }
   }
   var xml = xml.replace(/<\/?[0-9]{1,}>/g, "");
   return xml;
  },
  writeXML() {
   let collectExtensions = [
    {
     userSettings: {
      firstName: "test",
      lastName: "test",
      email: "test@test.com",
      mobile: "0511223300",
      physicalSite: "test",
      service: "",
      outputNumber: false,
      outputDisplayNumber: null,
      internalNumber: 100,
      sdaNumber: ["0173330000"],
     },
     globalSettings: {
      voiceMail: false,
      webClient: false,
      pushSmartphone: false,
      hotDesking: false,
      ipModelePhone: "",
     },
     notifications: {
      missedCalls: false,
      voiceMail: false,
     },
     restrictions: {
      internationalRestrictions: false,
      callRestrictions: false,
     },
     recording: "non",
     training: {
      user: "",
      admin: "",
     },
     others: {
      comment: "",
     },
     isComplete: true,
    },
   ];

   for (let indexExt = 0; indexExt < collectExtensions.length; indexExt++) {
    const elementExt = collectExtensions[indexExt];
    let objKey = xmlExtensionFile.Extension;
    objKey.AuthID = "Ddm4FkYawt";
    objKey.AuthPassword = "vz4Rj6wbbF";
    objKey.BreakTime = "";
    objKey.BusyDetection = "UsePBXStatus";
    objKey.CurrentProfile = "Available";
    objKey.DeliverAudio = "False";
    objKey.EmailAddress = "cyrille.georgel@encom-conseil.fr";
    objKey.Enabled = "True";
    objKey.SRTPMode = "SRTPDisabled";
    objKey.FirstName = "Cyrille";
    objKey.ForwardingRules = "";
    objKey.FwdProfiles = {
     FwdProfile: [
      {
       AwayRoute: {
        Internal: {
         AllHours: {
          To: "VoiceMail",
          Internal: "",
         },
         OutOfOfficeHours: {
          To: "VoiceMail",
          Internal: "",
         },
        },
        External: {
         AllHours: {
          To: "VoiceMail",
          Internal: "",
         },
         OutOfOfficeHours: {
          To: "VoiceMail",
          Internal: "",
         },
        },
       },
       BlockPushCalls: "False",
       BusyDetection: "UsePBXStatus",
       DisableRingGroupCalls: "True",
       ForceChatStatus: -1,
       ForceInternal: -1,
       ForceQueueStatus: 0,
       Name: "Custom 2",
       NoAnswerTimeout: 20,
       RingMyMobile: "False",
      },
      {
       AwayRoute: {
        Internal: {
         AllHours: {
          To: "VoiceMail",
          Internal: "",
         },
         OutOfOfficeHours: {
          To: "VoiceMail",
          Internal: "",
         },
        },
        External: {
         AllHours: {
          To: "VoiceMail",
          Internal: "",
         },
         OutOfOfficeHours: {
          To: "VoiceMail",
          Internal: "",
         },
        },
       },
       BlockPushCalls: "False",
       BusyDetection: "UsePBXStatus",
       DisableRingGroupCalls: "True",
       ForceChatStatus: -1,
       ForceInternal: -1,
       ForceQueueStatus: 0,
       Name: "Away",
       NoAnswerTimeout: 20,
       RingMyMobile: "False",
      },
      {
       AwayRoute: {
        Internal: {
         AllHours: {
          To: "VoiceMail",
          Internal: "",
         },
         OutOfOfficeHours: {
          To: "VoiceMail",
          Internal: "",
         },
        },
        External: {
         AllHours: {
          To: "VoiceMail",
          Internal: "",
         },
         OutOfOfficeHours: {
          To: "VoiceMail",
          Internal: "",
         },
        },
       },
       BlockPushCalls: "False",
       BusyDetection: "UsePBXStatus",
       DisableRingGroupCalls: "True",
       ForceChatStatus: -1,
       ForceInternal: -1,
       ForceQueueStatus: 0,
       Name: "Out of office",
       NoAnswerTimeout: 20,
       RingMyMobile: "False",
      },
      {
       AvailableRoute: {
        NoAnswer: {
         AllCalls: {
          To: "VoiceMail",
          Internal: "",
         },
         Internal: {
          To: "VoiceMail",
          Internal: "",
         },
        },
        Busy: {
         AllCalls: {
          To: "VoiceMail",
          Internal: "",
         },
         Internal: {
          To: "VoiceMail",
          Internal: "",
         },
        },
        NotRegistered: {
         AllCalls: {
          To: "VoiceMail",
          Internal: "",
         },
         Internal: {
          To: "VoiceMail",
          Internal: "",
         },
        },
       },
       BlockPushCalls: "False",
       BusyDetection: "UsePBXStatus",
       DisableRingGroupCalls: "False",
       ForceChatStatus: -1,
       ForceInternal: -1,
       ForceQueueStatus: -1,
       Name: "Custom 1",
       NoAnswerTimeout: 20,
       RingMyMobile: "False",
      },
      {
       AvailableRoute: {
        NoAnswer: {
         AllCalls: {
          To: "VoiceMail",
          Internal: "",
         },
         Internal: {
          To: "VoiceMail",
          Internal: "",
         },
        },
        Busy: {
         AllCalls: {
          To: "VoiceMail",
          Internal: "",
         },
         Internal: {
          To: "VoiceMail",
          Internal: "",
         },
        },
        NotRegistered: {
         AllCalls: {
          To: "VoiceMail",
          Internal: "",
         },
         Internal: {
          To: "VoiceMail",
          Internal: "",
         },
        },
       },
       BlockPushCalls: "False",
       BusyDetection: "UsePBXStatus",
       DisableRingGroupCalls: "False",
       ForceChatStatus: -1,
       ForceInternal: -1,
       ForceQueueStatus: -1,
       Name: "Available",
       NoAnswerTimeout: 20,
       RingMyMobile: "False",
      },
     ],
    };
    objKey.HidePresence = "False";
    objKey.Internal = "False";
    objKey.LastName = "Georgel";
    objKey.NoAnswerTimeout = 0;
    objKey.PhoneDevices = "";
    objKey.QueueStatus = "LoggedIn";
    objKey.RecordCalls = "False";
    objKey.SupportReinvite = "True";
    objKey.SupportReplaces = "True";
    objKey.UserStatus = "Available";
    objKey.VMEmailOptions = "None";
    objKey.VMEnabled = "True";
    objKey.VMPIN = 7220;
    objKey.VMPlayCallerID = "False";
    objKey.VMPlayMsgDateTime = "None";
    objKey.ContactImage = "7F8DE2B4.jpeg";
    objKey.Number = 213;
    objKey.PhoneBookEntries = "";
    objKey.Properties = {
     DNProperty: [
      {
       Name: "OFFICEHOURSPROPERTIES",
       Type: "String",
       Value: 1,
      },
      {
       Name: "DESKPHONE_PASSWORD",
       Type: "String",
       Value: "zH0FyxckdS",
      },
      {
       Name: "SERVICES_ACCESS_PASSWORD",
       Type: "String",
       Value: "OMj9TzQbrB",
      },
      {
       Name: "REQUEST_WELCOME_EMAIL",
       Type: "String",
       Value: 0,
      },
      {
       Name: "AZURE_USER_ID",
       Type: "String",
       Value: "4c7d270f-1193-4abd-9ab4-92f1df362288",
      },
      {
       Name: "AZURE_AD_USER",
       Type: "String",
       Value: 1,
      },
      {
       Name: "WC_WELCOME",
       Type: "String",
       Value: 2,
      },
      {
       Description: "Last updated extension params",
       Name: "LASTUPDATED",
       Type: "String",
       Value: "8f6a561a-dd4d-49a8-96b5-f60013694f58",
      },
      {
       Name: "QUICKMEETING_OPENLINK",
       Type: "String",
       Value: "5bec89c685f4bff3c4b043f4b11671b8bcc2d102",
      },
      {
       Name: "QUICKMEETING_KEY",
       Type: "String",
       Value: "QcK7CniJ4VmDPh61zGGZP7D4",
      },
      {
       Name: "AZURE_UPN",
       Type: "String",
       Value: "cyrille.georgel@encom-conseil.fr",
      },
      {
       Name: "MYPHONETEMPLATEINFO",
       Type: "String",
       Value:
        "&lt;MyPhoneTemplateInfo&gt;&lt;LocalInterface&gt;&lt;/LocalInterface&gt;&lt;Codec&gt;G729&lt;/Codec&gt;&lt;Codec&gt;PCMU&lt;/Codec&gt;&lt;Codec&gt;PCMA&lt;/Codec&gt;&lt;Codec&gt;G722&lt;/Codec&gt;&lt;/MyPhoneTemplateInfo&gt;",
      },
      {
       Name: "EXTGUID",
       Type: "String",
       Value: "2edb30fa-2beb-4e00-aec1-7d0f1e1a6ae7",
      },
      {
       Description: "3cx provisioning file",
       Name: "PROVFILE",
       Type: "String",
       Value: "3cxProv_2XD3GAI0N3213.xml",
      },
      {
       Name: "WM_FRIENDLYNAME",
       Type: "String",
       Value: "webmeeting653267",
      },
      {
       Name: "PUSH_EXTENSION",
       Type: "String",
       Value: 1,
      },
      {
       Name: "USETUNNEL",
       Type: "String",
       Value: 1,
      },
      {
       Name: "ALLOW_LAN_ONLY",
       Type: "String",
       Value: 1,
      },
     ],
    };
    objKey.Hours = "";
   }

   console.log("XML", this.OBJtoXML(xmlExtensionFile));
  },
  refreshWindow() {
   window.location.reload();
  },
  scrollToTop() {
   window.scrollTo({
    top: 0,
    behavior: "smooth",
   });
  },
  getNumberIdentifierObject(sdaObj) {
   const result = {};
   for (const key in sdaObj) {
    const { town, number, numberIdentifier } = sdaObj[key];
    if (!result[town]) {
     result[town] = {};
    }
    result[town][numberIdentifier] = number;
   }
   return result;
  },
  async createNumbers(obj) {
   let content = JSON.stringify(obj);
   const options = {
    method: "POST",
    url: `${this.$cookie.getCookie(
     "API"
    )}/api/v1/informationCollections/create-numbers?customerAccount=${this.account}`,
    data: content,
    headers: {
     "Content-Type": "application/json",
     Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
   };

   axios
    .request(options)
    .then((res) => {
     this.sdaTown = JSON.parse(JSON.stringify(res.data));
    })
    .catch((err) => {
     console.error(err.response);
     this.$emit("activeBanner");
     this.$emit("success", false);
     this.$emit("successMsg", err.response.data.message);
    });
  },
  filteredSdaTown() {
   return Object.keys(this.sdaTown).reduce((acc, town) => {
    const filteredSda = this.sdaTown[town].filter((sda) => {
     return !this.createdSda.includes(sda);
    });
    acc[town] = filteredSda;
    return acc;
   }, {});
  },
  /* updateSdaTown(town) {
     this.sdaTown.splice(this.sdaTown.indexOf(town), 1);
   this.filteredSdaTown();
  }, */
  updateSdaTown(sda) {
   Object.keys(this.sdaTown).forEach((town) => {
    const sdaIndex = this.sdaTown[town].indexOf(sda);
    if (sdaIndex !== -1) {
     this.createdSda.push(sda);
     //this.sdaTown[town].splice(sdaIndex, 1);
    }
   });
   this.filteredSdaTown();
  },
  removeSdaTown(...args) {
   let regex = /^\d+$/;

   let sdaTown = args[0];
   let callback = args[1];
   //if (!regex.test(sdaTown)) this.sdaTown.push(sdaTown);
   const index = this.createdSda.indexOf(sdaTown);
   if (index > -1 && !regex.test(sdaTown)) {
    this.createdSda.splice(index, 1);
   }
   callback();
   this.filteredSdaTown();
  },
 },

 computed: {
  ...mapGetters(["adminToken", "token"]),
 },

 watch: {
  formIndex: function (val) {
   this.setStatus(val);
   this.checkForDependencies(this.contentData, parseInt(val));
   this.setComplete(this.contentData);
  },
  goBackToDependentStep: function (val) {
   if (val) {
    setTimeout(() => {
     this.goBackToDependentStep = false;
    }, 5000);
   }
  },
 },
 mounted() {
  //-------------//
  /*   this.navIdx = parseInt(this.formIndex);
  this.setStatus();
  this.getPhoneData();
  this.resetUnsavedChangesValue();

  if (parseInt(this.formIndex) >= 0 && parseInt(this.formIndex) < this.steps.length) {
   this.setCollectData();
  } */
  //-------------//

  this.mountData();
  this.getLoadedContact();
  this.getDomains3cx();

  window.setInterval(() => {
   if (this.$route.path.includes("/customer-files/collect")) this.isCollectLocked();
  }, 45000);
 },

 created() {
  this.getCollect();
 },
};
</script>

<style></style>
