<template>
 <div class="w-full relative">
  <div class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
   <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
  </div>

  <div
   v-if="showElementList"
   class="pointer-events-none absolute inset-y-0 right-2 pl-3 flex items-center text-gray-400"
  >
   <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
    <path
     fill-rule="evenodd"
     d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
     clip-rule="evenodd"
    />
   </svg>
  </div>
  <div
   v-if="!showElementList"
   class="pointer-events-none absolute inset-y-0 right-2 pl-3 flex items-center text-gray-400"
  >
   <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
    <path
     fill-rule="evenodd"
     d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
     clip-rule="evenodd"
    />
   </svg>
  </div>

  <input
   @focus="showElementList = true"
   @blur="showElementList = false"
   @input="$emit('update:searchElement', $event.target.value)"
   list="quickList"
   v-model="searchElement"
   :class="[
    showElementList
     ? 'rounded-t-md border-t border-gray-300'
     : 'border rounded-md  border-gray-300 ',
    'w-full bg-white focus:border-gray-300 py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:ring-0 focus:outline-0 focus:text-gray-900 focus:placeholder-gray-400 sm:text-sm',
   ]"
   :placeholder="
    Array.isArray(selected) && selected.length > 0
     ? `${selected.length} elements selected`
     : $t('header.search')
   "
   type="search"
  />
  <transition
   v-if="hasDeepObject"
   enter-active-class="transition ease-out duration-100"
   enter-from-class="transform opacity-0 scale-95"
   enter-to-class="transform opacity-100 scale-100"
   leave-active-class="transition ease-in duration-75"
   leave-from-class="transform opacity-100 scale-100"
   leave-to-class="transform opacity-0 scale-95"
  >
   <div
    v-if="showClickableList || showElementList"
    @mouseover="showClickableList = true"
    @mouseleave="showClickableList = false"
    class="absolute z-20 bg-white w-full border-b border-l border-r rounded-b-md search_element_list"
   >
    <nav class="h-full overflow-y-auto" aria-label="Directory">
     <div
      v-for="site in Object.keys(searchData)"
      class="relative"
      :key="site"
      v-show="
       site.toLowerCase().includes(searchElement.toLowerCase()) ||
       searchData[site].some((number) => number.toLowerCase().includes(searchElement.toLowerCase()))
      "
     >
      <div
       class="sticky top-0 z-10 border-t border-b border-gray-200 bg-gray-100 px-6 py-1 text-sm font-medium text-gray-500"
      >
       <h3>{{ site }}</h3>
      </div>
      <ul role="list" class="relative z-0 divide-y divide-gray-200">
       <li
        v-for="number in searchData[site]"
        class="bg-white"
        :key="number.id"
        v-show="
         number.toLowerCase().includes(searchElement.toLowerCase()) ||
         site.toLowerCase().includes(searchElement.toLowerCase())
        "
       >
        <div
         class="relative flex items-center space-x-3 px-6 py-2 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500 hover:bg-gray-50"
        >
         <div class="min-w-0 flex-1">
          <a @click="addElements(number)" class="cursor-pointer focus:outline-none">
           <!-- Extend touch target to entire panel -->
           <span class="absolute inset-0" aria-hidden="true" />
           <p class="text-sm font-medium text-gray-900">{{ number }}</p>
          </a>
         </div>
        </div>
       </li>
      </ul>
     </div>
    </nav>
   </div>
  </transition>
  <transition
   v-else
   enter-active-class="transition ease-out duration-100"
   enter-from-class="transform opacity-0 scale-95"
   enter-to-class="transform opacity-100 scale-100"
   leave-active-class="transition ease-in duration-75"
   leave-from-class="transform opacity-100 scale-100"
   leave-to-class="transform opacity-0 scale-95"
  >
   <div
    v-if="showClickableList || showElementList"
    @mouseover="showClickableList = true"
    @mouseleave="showClickableList = false"
    class="absolute z-20 bg-white w-full border rounded-b-md search_element_list"
   >
    <div>
     <ul role="list" class="divide-y divide-gray-200">
      <li
       v-for="(element, idx) in searchData"
       :key="idx"
       :class="[
        selected.indexOf(element) >= 0 ? 'bg-green-300 font-semibold' : '',
        'p-1 cursor-pointer hover:bg-green-300 text-gray-700 text-xs',
       ]"
       @click="addElements(element)"
       v-show="
        searchElement.length > 0
         ? element.toLowerCase().includes(searchElement.toLowerCase())
         : true
       "
      >
       <p>{{ element }}</p>
      </li>
      <div v-if="searchElement.length > 0">
       <li
        v-for="(element, idx) in elementListConcatReduce"
        :key="idx"
        :class="[
         selected.indexOf(element) >= 0 ? 'bg-green-300 font-semibold' : '',
         'py-1 cursor-pointer hover:bg-green-300 text-gray-700 text-xs',
        ]"
        @click="addElements(element)"
        v-show="searchElement.length > 0 ? element.includes(searchElement) : true"
       >
        <p>{{ element }}</p>
       </li>
      </div>
      <!--   <infinite-loading @infinite="infiniteHandler">
       <template v-slot:no-more>
        {{ $t("noMoreData") }}
       </template>
      </infinite-loading> -->
     </ul>
    </div>
   </div>
  </transition>
 </div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";
import { SearchIcon } from "@heroicons/vue/outline";
import { mapGetters } from "vuex";

export default {
 props: [
  "searchData",
  "infiniteHandler",
  "selected",
  "urlType",
  "addElement",
  "slicedListIncludes",
  "elementListConcatReduce",
  "hasDeepObject",
 ],
 components: {
  InfiniteLoading,
  SearchIcon,
 },
 data() {
  return {
   searchElement: "",
   showElementList: false,
   showClickableList: false,
  };
 },
 computed: {},
 methods: {
  addElements(element) {
   let arr = [];
   if (Array.isArray(this.selected)) {
    if (this.selected.indexOf(element) < 0) {
     this.selected.push(element);
     arr.push(element);
    } else {
     this.selected.splice(this.selected.indexOf(element), 1);
     arr.splice(arr.indexOf(element), 1);
     this.$emit("removeElement");
    }
    this.$emit("addElement", arr);
   } else {
    if (arr.indexOf(element) < 0) {
     arr.push(element);
    } else {
     arr.splice(arr.indexOf(element), 1);
    }
    this.$emit("addElement", arr);
   }
  },
 },
};
</script>

<style scoped>
.search_element_list {
 max-height: 15rem;
 overflow-y: auto;
}
</style>
