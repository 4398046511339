<template>
 <div
  class="fixed impersonate_z_index inset-0 overflow-y-auto"
  aria-labelledby="modal-title"
  role="dialog"
  aria-modal="true"
 >
  <div
   class="flex items-center justify-center impersonate_card_height_position pt-4 px-4 pb-20 text-center sm:block sm:p-0"
  >
   <div
    class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
    aria-hidden="true"
    @click="this.$emit('isActive', false)"
   ></div>

   <!-- This element is to trick the browser into centering the modal contents. -->
   <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true"
    >&#8203;</span
   >
   <div
    class="rounded-lg inline-block align-bottom bg-white px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6"
   >
    <div class="relative bg-red-400 h-10 rounded-md" v-show="invalidTimeSlot">
     <div class="max-w-3xl mx-auto py-2 px-3 sm:px-6 lg:px-8">
      <div class="pr-16 sm:text-center sm:px-16">
       <p class="font-medium text-white">
        <span class="hidden md:inline">
         Invalid time slot (this time slot has already been added for this period).
        </span>
       </p>
      </div>
      <div
       class="absolute inset-y-0 right-0 pt-1 pr-1 flex items-start sm:pt-0 sm:pr-2 sm:items-center"
      >
       <button
        @click="invalidTimeSlot = false"
        type="button"
        class="flex p-1 rounded-md hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-white"
       >
        <span class="sr-only">Dismiss</span>
        <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
       </button>
      </div>
     </div>
    </div>
    <div class="space-y-6">
     <div class="bg-white rounded">
      <div class="md:grid md:grid-cols-3 md:gap-6">
       <div class="md:col-span-3">
        <p class="mt-1 text-sm text-gray-500">
         {{ $t("callqueueTable.formTimeSlotDescription") }}
        </p>
       </div>
       <div class="mt-5 md:mt-0 md:col-span-3">
        <form
         @submit.prevent="
          createTimeSlot(
           'Time Slot added successfully',
           'Unlink Call Queue',
           'Are you sure you want to create an time span for this specific call queue ? By doing this, you will automatically unlink this call queue with the call queue group related to it.',
           'Create and Unlink',
           'Cancel'
          )
         "
         method="POST"
        >
         <div class="grid grid-cols-6 gap-4">
          <div class="col-span-3">
           <div class="flex inline">
            <label for="weekdays" class="block text-sm font-bold text-gray-700"
             >{{ $t("callqueueTable.weekday") }}
            </label>
            <fieldset class="ml-5">
             <legend class="sr-only">Notifications</legend>
             <div class="relative flex items-start">
              <div class="flex items-center h-6">
               <input
                v-model="this.checked"
                :value="checked"
                id="comments"
                aria-describedby="comments-description"
                name="comments"
                type="checkbox"
                class="rounded focus:encom_border_input h-4 w-4 encom_ouvert_ticket_text border-gray-300"
               />
              </div>
              <div class="ml-2 text-sm">
               <label for="comments" class="font-small text-gray-700">
                {{ $t("callqueueTable.selectAllWorkingDays") }}
               </label>
              </div>
             </div>
            </fieldset>
           </div>
           <Menu>
            <MenuButton
             class="mt-1 relative text-left pl-3 p-1.5 w-full border border-gray-300 bg-white rounded focus:outline-none focus:encom_border_input sm:text-sm cursor-default"
             ><span class="block truncate"
              >{{ $t("callqueueTable.inputSelectDays") }}

              <kbd
               class="ml-1 inline-flex items-center border border-gray-200 rounded px-2 text-sm font-sans font-medium text-gray-400"
              >
               <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-3 w-3"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
               >
                <path
                 stroke-linecap="round"
                 stroke-linejoin="round"
                 stroke-width="3"
                 d="M8 7l4-4m0 0l4 4m-4-4v18"
                />
               </svg>
               Shift
              </kbd>
              {{ $t("callqueueTable.or") }}
              <kbd
               class="ml-1 inline-flex items-center border border-gray-200 rounded px-2 text-sm font-sans font-medium text-gray-400"
              >
               Ctrl
              </kbd>
             </span>
             <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" /> </span
            ></MenuButton>
            <transition
             enter-active-class="transition ease-out duration-100"
             enter-from-class="transform opacity-0 scale-95"
             enter-to-class="transform opacity-100 scale-100"
             leave-active-class="transition ease-in duration-75"
             leave-from-class="transform opacity-100 scale-100"
             leave-to-class="transform opacity-0 scale-95"
            >
             <MenuItems>
              <select
               v-model="this.form.dayOfWeek"
               id="weekdays"
               name="weekdays"
               required
               multiple
               class="capitalize mt-1 block w-full py-2 px-3 border border-gray-300 bg-white sm:text-sm"
              >
               <option v-for="dayOfWeek in weekDays" :key="dayOfWeek.id" :value="dayOfWeek.id">
                {{ $t(dayOfWeek.day) }}
               </option>
              </select></MenuItems
             >
            </transition>
           </Menu>
          </div>

          <div class="col-span-3 border rounded p-2">
           <label for="weekdays" class="block text-sm font-bold text-gray-700 pb-2">{{
            $t("callqueueTable.daySelected")
           }}</label>
           <p
            class="block capitalize text-sm pb-1 text-gray-700"
            v-if="this.form.dayOfWeek.length === 0 && !checked"
           >
            {{ $t("none") }}
           </p>
           <div class="grid grid-rows-3 grid-flow-col" v-if="!checked">
            <p
             v-for="id in this.form.dayOfWeek"
             :key="id"
             class="block capitalize text-sm pb-1 text-gray-700"
            >
             {{ $t(this.weekDays[id - 1].day) }}
            </p>
           </div>
           <div class="grid grid-rows-3 grid-flow-col" v-if="checked">
            <p
             v-for="dayOfWeek in workingDays"
             :key="dayOfWeek.id"
             class="block capitalize text-sm pb-1 text-gray-700"
            >
             {{ $t(dayOfWeek.day) }}
            </p>
           </div>
          </div>
          <div class="col-span-4 sm:col-span-4 flex space-x-2">
           <ToggleWithIcon :validated="is24h" @boolean-toggle="is24h = $event" />
           <label for="24h-timeslot" class="capitalize block text-sm font-bold text-gray-700">
            {{ $t("24hOption") }}
           </label>
          </div>

          <div v-show="is24h" class="sm:col-span-6 rounded-md bg-blue-50 p-2 -mt-2">
           <div class="flex">
            <div class="flex-shrink-0">
             <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              class="h-5 w-5 text-blue-400"
             >
              <path
               fill-rule="evenodd"
               d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z"
               clip-rule="evenodd"
              />
             </svg>
            </div>
            <div class="ml-3 flex-1 md:flex md:justify-between">
             <i18n-t keypath="24hOptionDescription" tag="p" class="text-sm text-blue-700">
              <template #days>
               <span v-for="(id, idx) in this.form.dayOfWeek" :key="id" class="capitalize">
                {{ $t(this.weekDays[id - 1].day)
                }}<span v-if="idx + 1 !== this.form.dayOfWeek.length">, </span>
               </span>
              </template>
             </i18n-t>
            </div>
           </div>
          </div>
          <div class="col-span-3 sm:col-span-3">
           <label for="start-timeslot" class="capitalize block text-sm font-bold text-gray-700">
            {{ $t("callqueueTable.start") }}
           </label>
           <div class="mt-1 relative rounded shadow-sm">
            <input
             required
             v-model="this.form.start"
             type="time"
             name="start-timeslot"
             id="start-timeslot"
             aria-invalid="true"
             :disabled="is24h"
             @input="this.checkmakingTimeSlot()"
             :class="{
              'mt-1 ring-red-500 border-red-300 focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm':
               this.checkmakingTimeSlot().checkStart ||
               this.checkmakingTimeSlot().checkBetween ||
               (this.pickedStartBigger && this.form.start && this.form.end),
              'bg-gray-100 text-gray-500': is24h,
             }"
             class="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded"
            />
            <div
             v-if="
              this.checkmakingTimeSlot().checkStart ||
              this.checkmakingTimeSlot().checkBetween ||
              (this.pickedStartBigger && this.form.start && this.form.end)
             "
             class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
            >
             <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
            </div>
           </div>
           <p
            v-if="this.checkmakingTimeSlot().checkStart"
            class="mt-1 ml-1 text-sm text-red-600"
            id="email-error"
           >
            {{ $t("callqueueTable.checkStart") }}
           </p>
           <p
            v-else-if="this.checkmakingTimeSlot().checkBetween"
            class="mt-1 ml-1 text-sm text-red-600"
            id="email-error"
           >
            {{ $t("callqueueTable.checkBetween") }}
           </p>
           <p
            v-else-if="this.pickedStartBigger && this.form.start && this.form.end"
            class="mt-1 ml-1 text-sm text-red-600"
            id="email-error"
           >
            {{ $t("callqueueTable.pickedStartBigger") }}
           </p>
          </div>

          <div class="col-span-3 sm:col-span-3">
           <label for="end-timeslot" class="capitalize block text-sm font-bold text-gray-700">
            {{ $t("callqueueTable.end") }}
           </label>

           <div class="mt-1 relative rounded shadow-sm">
            <input
             v-model="this.form.end"
             @input="checkmakingTimeSlot()"
             type="time"
             required
             name="start-timeslot"
             id="start-timeslot"
             aria-invalid="true"
             :class="{
              'mt-1 ring-red-500 border-red-300 focus:ring-red-500 focus:border-red-500 block w-full shadow-sm sm:text-sm':
               this.checkmakingTimeSlot().checkStart ||
               this.checkmakingTimeSlot().checkBetween ||
               (this.pickedStartBigger && this.form.start && this.form.end),
              'bg-gray-100 text-gray-500': is24h,
             }"
             class="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded"
            />

            <div
             v-if="
              this.checkmakingTimeSlot().checkEnd ||
              this.checkmakingTimeSlot().checkBetween ||
              (this.pickedStartBigger && this.form.start && this.form.end)
             "
             class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
            >
             <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
            </div>
           </div>
           <p
            v-if="this.checkmakingTimeSlot().checkEnd"
            class="mt-1 ml-1 text-sm text-red-600"
            id="email-error"
           >
            {{ $t("callqueueTable.checkEnd") }}
           </p>
          </div>

          <!-- <div class="col-span-3 capitalize">
                <v-date-picker
                  :min-date="new Date()"
                  :locale="$i18n.locale"
                  v-model="this.form.day"
                  @click="
                    checkmakingOfBankHolidays(),
                      checkmakingOfDate(),
                      (this.closeNotification = true),
                      (this.errorMessage = false)
                  "
                  title-position="left"
                  trim-weeks
                  is-expanded
                  color="teal"
                >
                </v-date-picker>
              </div> -->
          <div v-if="this.form.day || this.errorMessage" class="col-span-3 border rounded p-2">
           <h3 class="text-lg pb-2 font-semibold leading-6 text-gray-900">
            {{ $t("callqueueTable.dateSelected") }}
           </h3>
           <p class="block capitalize text-base pb-2 text-gray-700">
            {{ this.fullFormatDateFrench(this.form.day) }}
           </p>

           <transition :name="computedTransition">
            <div
             v-if="this.closeNotification"
             :class="[
              !checkmakingOfDate() && !this.errorMessage
               ? 'rounded bg-green-50 p-4'
               : 'rounded bg-red-50 p-4',
             ]"
            >
             <div class="flex">
              <div class="flex-shrink-0">
               <CheckCircleIcon
                v-show="!checkmakingOfDate() && !this.errorMessage"
                class="h-5 w-5 text-green-400"
                aria-hidden="true"
               />
               <XCircleIcon
                v-show="checkmakingOfDate() || this.errorMessage"
                class="h-5 w-5 text-red-400"
                aria-hidden="true"
               />
              </div>
              <div class="ml-3">
               <p
                :class="[
                 !checkmakingOfDate() && !this.errorMessage
                  ? 'text-sm font-medium text-green-800'
                  : 'text-sm font-bold text-red-800',
                ]"
               >
                {{
                 !checkmakingOfDate() && !this.errorMessage
                  ? $t("callqueueTable.newExceptionAdded")
                  : $t("callqueueTable.newExceptionError")
                }}
               </p>

               <p v-show="checkmakingOfDate()" class="text-sm text-red-700">
                {{ $t("callqueueTable.newExceptionExist") }}.
               </p>
               <p v-show="this.errorMessage" class="text-sm text-red-700">
                {{ $t("callqueueTable.selectExceptionError") }}.
               </p>
              </div>
              <div class="ml-auto pl-3">
               <div class="-mx-1.5 -my-1.5">
                <button
                 type="button"
                 @click="this.closeNotification = false"
                 :class="[
                  !checkmakingOfDate() && !this.errorMessage
                   ? 'rounded inline-flex bg-green-50 rounded p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600'
                   : 'rounded inline-flex bg-red-50 rounded p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-600',
                 ]"
                >
                 <span class="sr-only">Dismiss</span>
                 <XIcon class="h-5 w-5" aria-hidden="true" />
                </button>
               </div>
              </div>
             </div>
            </div>
           </transition>
           <label for="reference" class="capitalize block text-sm font-normal pt-5 text-gray-700">{{
            $t("callqueueTable.reference")
           }}</label>
           <input
            v-model="this.form.reference"
            required
            type="text"
            name="reference"
            id="reference"
            autocomplete="reference"
            class="mt-1 focus:encom_border_input block w-full shadow-sm sm:text-sm border-gray-300 rounded"
           />
          </div>
          <!-- <div
                v-if="!this.openBankHolidaysForm && !this.call_queue_group_id"
                class="col-span-6"
              >
                <label
                  for="street-address"
                  class="capitalize block text-sm font-normal text-gray-700"
                  >Destination</label
                >
                <input
                  v-model="this.form.destination"
                  type="tel"
                  name="destination"
                  id="destination"
                  autocomplete="destination"
                  class="mt-1 focus:encom_border_input  block w-full shadow-sm sm:text-sm border-gray-300 rounded"
                />
                <p class="mt-2 text-sm text-gray-500">
                  {{ $t("callqueueTable.destinationDescription") }}
                </p>
              </div> -->
         </div>

         <div class="flex justify-end mt-5">
          <button
           @click="closeForm()"
           type="button"
           class="rounded bg-white py-2 px-4 border border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-50"
          >
           {{ $t("ticket.cancel") }}
          </button>
          <button
           type="submit"
           class="capitalize rounded ml-3 disabled inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium body__table--header text-white"
          >
           {{ $t("add") }}
          </button>
         </div>
        </form>
       </div>
      </div>
     </div>
    </div>
   </div>
  </div>
 </div>
 <ask-confirmation-dialog ref="askConfirmationDialog"></ask-confirmation-dialog>
 <ComfirmationDialog
  v-if="this.showMessage"
  :messageToShow="this.messageToShow"
  :subMessageToShow="this.subMessageToShow"
 />
</template>

<script>
import axios from "axios";

import {
 ExclamationIcon,
 ExclamationCircleIcon,
 InformationCircleIcon,
 CheckCircleIcon,
 SelectorIcon,
 XCircleIcon,
 XIcon,
} from "@heroicons/vue/solid";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import ToggleWithIcon from "./ToggleWithIcon.vue";
import AskConfirmationDialog from "../../AskConfirmationDialog.vue";
import ComfirmationDialog from "../../ConfirmationDialog.vue";
const account = localStorage.getItem("account");

const weekDays = [
 { id: 1, day: "weekdays.mon" },
 { id: 2, day: "weekdays.tue" },
 { id: 3, day: "weekdays.wed" },
 { id: 4, day: "weekdays.thu" },
 { id: 5, day: "weekdays.fri" },
 { id: 6, day: "weekdays.sat" },
 { id: 7, day: "weekdays.sun" },
];
const workingDays = [
 { id: 1, day: "weekdays.mon" },
 { id: 2, day: "weekdays.tue" },
 { id: 3, day: "weekdays.wed" },
 { id: 4, day: "weekdays.thu" },
 { id: 5, day: "weekdays.fri" },
];
export default {
 props: [
  "openTimeSlotForm",
  "openExceptionForm",
  "openBankHolidaysForm",
  "call_queue_exceptions",
  "call_queue_time_spans",
  "call_queue_group_time_spans",
  "call_queue_group_exceptions",
  "formatDateFrench",
  "fullFormatDateFrench",
  "timeSlotList",
 ],
 components: {
  CheckCircleIcon,
  ExclamationIcon,
  InformationCircleIcon,
  ExclamationCircleIcon,
  SelectorIcon,
  XCircleIcon,
  XIcon,
  ComfirmationDialog,
  AskConfirmationDialog,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  ToggleWithIcon,
 },
 setup() {
  return {
   weekDays,
   workingDays,
  };
 },
 data() {
  return {
   account,
   form: {
    day: "",
    dayOfWeek: [],
    fullDates: [],
    start: "",
    end: "",
    destination: null,
    reference: null,
   },
   weekDay: true,
   specificDay: false,
   days: [],
   pickedStartBigger: false,
   checked: false,
   showMessage: false,
   errorMessage: false,
   closeNotification: true,
   withTransition: true,
   messageToShow: "",
   subMessageToShow: "",
   frenchHolidaysOver12Months: {},
   frenchHolidaysDateOver12Months: [],
   invalidTimeSlot: false,
   is24h: false,
  };
 },
 computed: {
  computedTransition() {
   return this.withTransition && "fade";
  },
  dates() {
   return this.days.map((day) => day.date);
  },
  attributes() {
   return this.dates.map((date) => ({
    highlight: true,
    dates: date,
   }));
  },
 },
 methods: {
  async getFrenchHolidaysOver12Months() {
   const res = await axios.get(
    `${this.$cookie.getCookie("API")}/api/v1/helpers/time/frenchHolidaysOver12Months`,
    {
     headers: {
      "Content-Type": "application/json",
     },
    }
   );

   try {
    this.frenchHolidaysOver12Months = res.data.response;
    for (const key in this.frenchHolidaysOver12Months) {
     if (Object.hasOwnProperty.call(this.frenchHolidaysOver12Months, key)) {
      this.frenchHolidaysDateOver12Months.push(this.frenchHolidaysOver12Months[key]);
     }
    }
   } catch (error) {
    this.errorHandling(error);
    this.$emit("activeBanner");
    this.$emit("success", false);
    this.$emit("successMsg", this.errorDataResponse);
   }
  },
  isValidTimeSlot(data) {
   let isValid = true;
   let dataDaysOfWeek = data.daysOfWeek;
   let dataStartSplit = data.start.split(":");
   let dataEndSplit = data.end.split(":");
   let dataStart = parseInt(dataStartSplit[0] + dataStartSplit[1]);
   let dataEnd = parseInt(dataEndSplit[0] + dataEndSplit[1]);
   if (this.call_queue_time_spans && this.call_queue_time_spans.length > 0) {
    for (let index = 0; index < this.call_queue_time_spans.length; index++) {
     const element = this.call_queue_time_spans[index];
     let elementDaysOfWeek = element.daysOfWeek;
     let elementStartSplit = element.start.split(":");
     let elementEndSplit = element.end.split(":");
     let elementStart = parseInt(elementStartSplit[0] + elementStartSplit[1]);
     let elementEnd = parseInt(elementEndSplit[0] + elementEndSplit[1]);

     if (elementDaysOfWeek.some((r) => dataDaysOfWeek.includes(r))) {
      if (
       (dataStart >= elementStart && dataStart <= elementEnd) ||
       (dataEnd >= elementStart && dataEnd <= elementEnd) ||
       (dataStart <= elementStart && dataEnd >= elementStart) ||
       (dataStart <= elementEnd && dataEnd >= elementEnd)
      ) {
       isValid = false;
      }
     }
    }
   }
   return isValid;
  },
  async createTimeSlot(msg, title, confirmationMessage, confirmButton, goBack) {
   let data = {};
   if (this.checked) {
    data = {
     daysOfWeek: [1, 2, 3, 4, 5],
     start: this.form.start,
     end: this.form.end,
    };
    //create a timeslot for a call queue group and for all the day
   } else {
    //create a timeslot for a call queue group and for days specified by the user
    if (this.form.dayOfWeek.length > 0) {
     data = {
      daysOfWeek: this.form.dayOfWeek,
      start: this.form.start,
      end: this.form.end,
     };
    }
   }
   if (Object.keys(data).length > 0 && this.isValidTimeSlot(data)) {
    this.form = {
     day: "",
     dayOfWeek: [],
     fullDates: [],
     start: "",
     end: "",
     destination: null,
     reference: null,
    };
    this.$emit("pushTimeSlot", data);
   } else {
    this.invalidTimeSlot = true;
   }
  },
  checkmakingOfDate() {
   if (this.call_queue_group_exceptions) {
    let pickedDay = JSON.stringify(this.form.day).slice(1, 11) + "T00:00:00.000000Z";
    let day = this.call_queue_group_exceptions.find((day) => day.day === pickedDay);
    if (day) {
     return true;
    } else {
     return false;
    }
   } else {
    let pickedDay = JSON.stringify(this.form.day).slice(1, 11) + "T00:00:00.000000Z";
    let day = this.call_queue_exceptions.find((day) => day.day === pickedDay);
    if (day) {
     return true;
    } else {
     return false;
    }
   }
  },
  checkmakingTimeSlot() {
   let pickedStart = this.form.start;
   let pickedStartSplit = pickedStart.split(":");
   let pickedStartInt = parseInt(pickedStartSplit[0] + pickedStartSplit[1]);
   let pickedEnd = this.form.end;
   let pickedEndSplit = pickedEnd.split(":");
   let pickedEndInt = parseInt(pickedEndSplit[0] + pickedEndSplit[1]);
   let checkDay = [];
   let checkStart;
   let checkEnd;
   let checkBetween;
   this.pickedStartBigger = false;

   //Check if i'm inside a callqueue group detail
   if (this.call_queue_group_time_spans) {
    // check for timeslot for specific day
    if (this.specificDay) {
     let pickedDay = this.form.fullDates;
     for (const value of pickedDay) {
      const dateCreated = new Date(value);
      const formatedDate =
       dateCreated.getFullYear() +
       "-" +
       ("0" + dateCreated.getDate()).slice(-2) +
       "-" +
       ("0" + (dateCreated.getMonth() + 1)).slice(-2);
      const res = this.call_queue_group_time_spans.filter(
       (dayOfWeek) => dayOfWeek.fullDate === formatedDate
      );
      if (res.length > 0 && pickedStartInt < pickedEndInt) {
       for (let index = 0; index < res.length; index++) {
        const timeSpans = res[index];
        checkDay.push(timeSpans);
        checkStart = checkDay.find(
         (checkStart) =>
          //Issue on only picked start
          //picked start bigger than existed start and smaller than existed end
          pickedStart >= checkStart.start.slice(0, 5) && pickedStart <= checkStart.end.slice(0, 5)
        );
        checkEnd = checkDay.find(
         (checkEnd) =>
          //Issue on only picked end
          //picked end bigger than existed start and smaller than existed end
          pickedEnd >= checkEnd.start.slice(0, 5) && pickedEnd <= checkEnd.end.slice(0, 5)
        );
        checkBetween = checkDay.find(
         (checkBetween) =>
          //Issue on picked start and picked end
          //picked start smaller than existed start and picked end smaller than existed end
          checkBetween.end.slice(0, 5) <= pickedEnd && pickedStart <= checkBetween.start.slice(0, 5)
        );
       }
      } else if (pickedStartInt >= pickedEndInt) {
       //picked start bigger than picked end
       this.pickedStartBigger = true;
      }
     }
    } else {
     let pickedDay = this.form.dayOfWeek;
     for (const value of pickedDay) {
      const res = this.call_queue_group_time_spans.filter(
       (dayOfWeek) => dayOfWeek.dayOfWeek === value
      );
      if (res.length > 0 && pickedStartInt < pickedEndInt) {
       for (let index = 0; index < res.length; index++) {
        const timeSpans = res[index];
        checkDay.push(timeSpans);
        checkStart = checkDay.find(
         (checkStart) =>
          //Issue on only picked start
          //picked start bigger than existed start and smaller than existed end
          pickedStart >= checkStart.start.slice(0, 5) && pickedStart <= checkStart.end.slice(0, 5)
        );
        checkEnd = checkDay.find(
         (checkEnd) =>
          //Issue on only picked end
          //picked end bigger than existed start and smaller than existed end
          pickedEnd >= checkEnd.start.slice(0, 5) && pickedEnd <= checkEnd.end.slice(0, 5)
        );
        checkBetween = checkDay.find(
         (checkBetween) =>
          //Issue on picked start and picked end
          //picked start smaller than existed start and picked end smaller than existed end
          checkBetween.end.slice(0, 5) <= pickedEnd && pickedStart <= checkBetween.start.slice(0, 5)
        );
       }
      } else if (pickedStartInt >= pickedEndInt) {
       //picked start bigger than picked end
       this.pickedStartBigger = true;
      }
     }
    }
   } else {
    //inside a callqueue detail
    if (this.specificDay) {
     //check for timeslot for specific day
     let pickedDay = this.form.fullDates;

     for (const value of pickedDay) {
      const dateCreated = new Date(value);
      const formatedDate =
       dateCreated.getFullYear() +
       "-" +
       ("0" + dateCreated.getDate()).slice(-2) +
       "-" +
       ("0" + (dateCreated.getMonth() + 1)).slice(-2);
      const res = this.call_queue_time_spans.filter(
       (dayOfWeek) => dayOfWeek.fullDate === formatedDate
      );
      if (res.length > 0 && pickedStartInt < pickedEndInt) {
       for (let index = 0; index < res.length; index++) {
        const timeSpans = res[index];
        checkDay.push(timeSpans);
        checkStart = checkDay.find(
         (checkStart) =>
          //Issue on only picked start
          //picked start bigger than existed start and smaller than existed end
          pickedStart >= checkStart.start.slice(0, 5) && pickedStart <= checkStart.end.slice(0, 5)
        );
        checkEnd = checkDay.find(
         (checkEnd) =>
          //Issue on only picked end
          //picked end bigger than existed start and smaller than existed end
          pickedEnd >= checkEnd.start.slice(0, 5) && pickedEnd <= checkEnd.end.slice(0, 5)
        );
        checkBetween = checkDay.find(
         (checkBetween) =>
          //Issue on picked start and picked end
          //picked start smaller than existed start and picked end smaller than existed end
          checkBetween.end.slice(0, 5) <= pickedEnd && pickedStart <= checkBetween.start.slice(0, 5)
        );
       }
      } else if (pickedStartInt >= pickedEndInt) {
       this.pickedStartBigger = true;
      }
     }
    } else {
     let pickedDay = this.form.dayOfWeek;
     for (const value of pickedDay) {
      const res = this.call_queue_time_spans.filter((dayOfWeek) => dayOfWeek.dayOfWeek === value);
      if (res.length > 0 && pickedStartInt < pickedEndInt) {
       for (let index = 0; index < res.length; index++) {
        const timeSpans = res[index];
        checkDay.push(timeSpans);
        checkStart = checkDay.find(
         (checkStart) =>
          //Issue on only picked start
          //picked start bigger than existed start and smaller than existed end
          pickedStart >= checkStart.start.slice(0, 5) && pickedStart <= checkStart.end.slice(0, 5)
        );
        checkEnd = checkDay.find(
         (checkEnd) =>
          //Issue on only picked end
          //picked end bigger than existed start and smaller than existed end
          pickedEnd >= checkEnd.start.slice(0, 5) && pickedEnd <= checkEnd.end.slice(0, 5)
        );
        checkBetween = checkDay.find(
         (checkBetween) =>
          //Issue on picked start and picked end
          //picked start smaller than existed start and picked end smaller than existed end
          checkBetween.end.slice(0, 5) <= pickedEnd && pickedStart <= checkBetween.start.slice(0, 5)
        );
       }
      } else if (pickedStartInt >= pickedEndInt) {
       this.pickedStartBigger = true;
      }
     }
    }
   }

   return { checkStart, checkEnd, checkBetween };
  },
  sendMessage(msg, subMsg) {
   this.showMessage = true;
   this.messageToShow = msg;
   this.subMessageToShow = subMsg;
  },
  sendErrorMessage() {
   this.errorMessage = true;
   this.messageToShow = "Sorry, something went wrong";
   this.subMessageToShow = "Select an exception date in the calendar";
  },
  closeForm() {
   this.checked = false;
   this.form.dayOfWeek = [];
   this.form.fullDates = [];
   this.form.day = "";
   this.form.start = "";
   this.form.end = "";
   this.form.destination = "";
   this.form.reference = "";
   this.$emit("closeForm");
  },
  cleanForm() {
   this.checked = false;
   this.form.dayOfWeek = [];
   this.form.fullDates = [];
   this.form.day = "";
   this.form.start = "";
   this.form.end = "";
   this.form.destination = "";
   this.form.reference = "";
  },
 },
 mounted() {
  this.getFrenchHolidaysOver12Months();
  console.log("call_queue_time_spans", this.call_queue_time_spans);
 },
 watch: {
  is24h: function (bool) {
   if (bool) {
    this.form.start = "00:00";
    this.form.end = "23:59";
   } else {
    this.form.start = "";
    this.form.end = "";
   }
  },
  checked: function (bool) {
   bool ? (this.form.dayOfWeek = [1, 2, 3, 4, 5]) : (this.form.dayOfWeek = []);
  },
 },
};
</script>

<style>
.fade-leave-active {
 transition: opacity 0.5s;
}
.fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
 opacity: 0;
}
.impersonate_z_index {
 z-index: 9999;
}
.impersonate_card_height_position {
 min-height: 100vh;
}
</style>
