<template>
 <div class="bg-white mx-5 px-4 py-5 sm:rounded-lg sm:p-6 shadow">
  <div class="sm:flex sm:items-center">
   <div class="sm:flex-auto">
    <h1 class="capitalize text-lg font-medium leading-6 text-gray-900">
     {{ $t("users") }}
    </h1>
    <p class="mt-2 text-sm text-gray-700">{{ $t("usersCollectDetail") }}.</p>
   </div>
   <button
    v-show="people.users && people.users.length > 0"
    type="button"
    @mouseenter="visible = true"
    @mouseleave="visible = false"
    :class="[
     'wrapper uppercase inline-flex items-center p-1.5 border border-transparent shadow-sm text-sm font-medium rounded-md text-white encom_primary encom_primary_hover focus:outline-none encom_ring_color',
    ]"
    @click="(showImportForm = !showImportForm), (showForm = false), (isEdited = false)"
   >
    <font-awesome-icon icon="file-import" class="h-5 w-5" aria-hidden="true" />
    <span v-if="visible" class="ml-2">{{ $t("import") }}</span>
   </button>
   <button
    v-show="people.users && people.users.length > 0"
    type="button"
    :title="$t('addUser')"
    class="inline-flex items-center p-1.5 ml-5 border border-transparent shadow-sm text-sm font-medium rounded-md text-white encom_primary encom_primary_hover focus:outline-none encom_ring_color"
    @click="
     (showForm = !showForm),
      (showImportForm = false),
      (isEdited = false),
      (elementNumberEdited = undefined)
    "
   >
    <PlusIcon class="h-5 w-5" aria-hidden="true" />
   </button>
  </div>

  <div v-show="showForm || isEdited">
   <CollectAddUsers
    :key="isEdited"
    :isEdited="isEdited"
    :showForm="showForm"
    :contentData="contentData"
    :dependencies="dependencies"
    :userChosen="userChosen"
    :createdSda="createdSda"
    :idx="isEdited ? userIdx : null"
    :navIdx="navIdx"
    :fileId="fileId"
    :companyAddress="companyAddress"
    :createdExtensions="createdExtensions"
    :sdaTown="sdaTown"
    :phoneData="phoneData"
    :customerFileId="customerFileId"
    :bounceButton="bounceButton"
    :unsavedChangesBool="unsavedChangesBool"
    :elementRef="elementRef"
    :collectType="collectType"
    @update-sda-town="this.$emit('updateSdaTown', $event)"
    @remove-sda-town="(...args) => $emit('removeSdaTown', ...args)"
    @is-edited="this.$emit('isEdited', $event)"
    @close-form="
     (showForm = $event),
      (isEdited = $event),
      (elementNumberEdited = undefined),
      this.$emit('unsavedChanges', false, '')
    "
    @users="(...args) => this.sendFormFromNew(...args)"
    @users-form="(...args) => this.$emit('usersForm', ...args)"
    @edit-user="(...args) => this.editForm(...args)"
    @unsaved-changes="(...args) => $emit('unsavedChanges', ...args)"
   />
  </div>
  <div v-show="showImportForm">
   <CollectImportUsers
    :key="isEdited"
    :fileId="this.fileId"
    :contentData="contentData"
    @close-form="showImportForm = $event"
    @new-users="(...args) => this.sendFormFromImport(...args)"
   />
  </div>
  <div
   v-if="this.people.users && this.people.users.length > 0"
   class="-mx-4 mt-2 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg"
  >
   <table class="min-w-full divide-y divide-gray-300">
    <thead class="bg-gray-50">
     <tr>
      <th
       v-for="title in tableHeaders"
       :key="title"
       scope="col"
       :class="[
        title.name === thead[0] ? 'pl-4 sm:pl-6 pr-3' : 'hidden px-3 lg:table-cell',
        'px-3 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase',
       ]"
      >
       {{ $t(title.name) }}
       <font-awesome-icon
        v-if="title.icon"
        :icon="title.icon"
        class="text-blue-400 h-4 w-4 ml-1 mb-1 icon alt"
        :title="title.description"
       />
      </th>
      <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
       <span class="sr-only">Edit</span>
      </th>
     </tr>
    </thead>
    <tbody class="divide-y divide-gray-200 bg-white">
     <tr
      v-for="(user, idx) in pagination().querySet"
      :key="user.email"
      :class="idx % 2 === 0 ? undefined : 'bg-gray-50'"
     >
      <td
       class="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6"
      >
       {{ user.userSettings.internalNumber ? user.userSettings.internalNumber : "No Number" }}
       <dl class="font-normal lg:hidden">
        <dt class="sr-only sm:hidden">Name</dt>
        <dd class="mt-1 truncate text-gray-500 sm:hidden">
         {{ user.userSettings.firstName }}
         {{ user.userSettings.lastName }}
        </dd>
        <dt class="sr-only">Email</dt>
        <dd class="mt-1 truncate text-gray-700">
         {{ user.userSettings.email }}
        </dd>
       </dl>
      </td>
      <td class="px-3 py-4 text-sm truncate text-gray-500">
       <span v-for="(sda, idx) in user.userSettings.sdaNumber" :key="idx">
        {{ sda.toString().length === 9 ? "0" + sda : sda
        }}{{ user.userSettings.sdaNumber.length === idx + 1 ? "" : ", " }}
       </span>
      </td>
      <td class="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
       {{ user.userSettings.firstName }}
      </td>
      <td class="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
       {{ user.userSettings.lastName }}
      </td>
      <td class="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
       {{ user.userSettings.email }}
      </td>
      <td class="capitalize hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
       {{ this.userTraining(user.training) }}
      </td>
      <td class="p-4 whitespace-nowrap text-right text-sm font-medium flex justify-end">
       <button
        class="rounded p-1 shadow-sm border-2 border-black rounded-md body__table--button cursor-pointer tooltip mr-1"
        @click="
         (userIdx = idx),
          (userChosen = user),
          (isEdited = !isEdited),
          (showForm = false),
          (elementNumberEdited = user.userSettings.internalNumber)
        "
        :title="$t('edit')"
       >
        <svg
         xmlns="http://www.w3.org/2000/svg"
         class="h-4 w-4"
         viewBox="0 0 20 20"
         fill="currentColor"
        >
         <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
         <path
          fill-rule="evenodd"
          d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
          clip-rule="evenodd"
         />
        </svg>
       </button>
       <a
        @click="
         deleteUser(
          idx,
          $t('deleteMessage'),
          $t('deleteUser') + ' ' + `${user.userSettings.firstName} ${user.userSettings.lastName}`,
          $t('userDeletedSuccessfully'),
          $t('delete'),
          $t('cancel'),
          user.userSettings.internalNumber
         )
        "
        href="javascript:void(0)"
        :title="$t('delete')"
        class="p-1 shadow-sm border-2 border-black rounded-md body__table--button cursor-pointer tooltip mr-1"
       >
        <svg
         xmlns="http://www.w3.org/2000/svg"
         class="h-4 w-4"
         viewBox="0 0 20 20"
         fill="currentColor"
        >
         <path
          fill-rule="evenodd"
          d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
          clip-rule="evenodd"
         />
        </svg>
       </a>
      </td>
     </tr>
    </tbody>
   </table>
   <nav
    class="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
    aria-label="Pagination"
   >
    <div class="hidden sm:block">
     <p class="text-sm text-gray-700">
      {{ $t("ticketTable.showing") }}
      {{ " " }}
      <span class="font-medium">{{ this.rows * this.page - this.rows + 1 }}</span>
      {{ " " }}
      {{ $t("ticketTable.to") }}
      {{ " " }}
      <span class="font-medium">{{ getPageLength() }} </span>
      {{ " " }}
      {{ $t("ticketTable.of") }}
      {{ " " }}
      <span class="font-medium">{{ this.people.users.length }}</span>
      {{ " " }}
      {{ $t("ticketTable.results") }}
     </p>
    </div>
    <div class="flex flex-1 justify-between sm:justify-end">
     <a
      @click="previousPage"
      class="cursor-pointer relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
     >
      {{ $t("ticketTable.previous") }}
     </a>
     <a
      @click="nextPage"
      class="cursor-pointer relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
     >
      {{ $t("ticketTable.next") }}
     </a>
    </div>
   </nav>
  </div>
  <div v-else class="text-center max-w-lg mx-auto mt-5">
   <svg
    class="mx-auto h-12 w-12 text-gray-400"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 48 48"
    aria-hidden="true"
   >
    <path
     stroke-linecap="round"
     stroke-linejoin="round"
     stroke-width="2"
     d="M34 40h10v-4a6 6 0 00-10.712-3.714M34 40H14m20 0v-4a9.971 9.971 0 00-.712-3.714M14 40H4v-4a6 6 0 0110.713-3.714M14 40v-4c0-1.313.253-2.566.713-3.714m0 0A10.003 10.003 0 0124 26c4.21 0 7.813 2.602 9.288 6.286M30 14a6 6 0 11-12 0 6 6 0 0112 0zm12 6a4 4 0 11-8 0 4 4 0 018 0zm-28 0a4 4 0 11-8 0 4 4 0 018 0z"
    />
   </svg>

   <div class="mt-3 space-x-4">
    <button
     type="button"
     class="capitalize inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white encom_primary encom_primary_hover focus:outline-none encom_ring_color"
     @click="(showImportForm = true), (showForm = false)"
    >
     <font-awesome-icon icon="file-import" class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
     {{ $t("import") }}
    </button>
    <button
     type="button"
     class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white encom_primary encom_primary_hover focus:outline-none encom_ring_color"
     @click="showForm = true"
    >
     <PlusIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
     {{ $t("addUser") }}
    </button>
   </div>
  </div>
 </div>
 <div class="bg-white mt-5 mx-5 px-4 py-5 sm:rounded-lg sm:p-6 shadow">
  <form class="space-y-6" @submit.prevent="postCollect(this.$t('informationSaved'))" method="POST">
   <div class="md:grid md:grid-cols-5 md:gap-8">
    <div class="md:col-span-2">
     <h3 class="text-lg font-medium leading-6 text-gray-900">
      {{ $t("nonConfiguredNumbers") }}
     </h3>

     <p class="mt-1 text-sm font-medium text-gray-700">{{ $t("nonConfiguredNumbersDetail") }}.</p>
    </div>
    <div class="mt-5 space-y-6 md:col-span-2 md:col-start-3 md:mt-0">
     <div class="grid grid-cols-6 gap-6">
      <div class="col-span-5">
       <label for="out-work-time" class="block text-sm font-semibold text-gray-700">
        Choisissez la destination d'aboutissement*
       </label>
       <span
        v-if="people.unconfiguredUsersOutcome"
        class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800"
       >
        {{ people.unconfiguredUsersOutcome }}
        <a
         :title="$t('delete')"
         class="cursor-pointer flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-gray-500 hover:bg-gray-200 hover:text-gray-500 focus:outline-none focus:bg-gray-500 focus:text-white"
         @click="people.unconfiguredUsersOutcome = ''"
        >
         <span class="sr-only">Remove number</span>
         <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
          <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
         </svg>
        </a>
       </span>

       <div class="flex gap-2">
        <select
         v-model="people.type"
         id="type"
         name="type"
         class="mt-1 block h-10 shadow-sm sm:text-sm border-gray-300 rounded-md flex-none"
        >
         <option value="0">Extensions</option>
         <option value="1">File d'attente</option>
         <option value="2">SVI</option>
         <option value="3">Autre</option>
        </select>
        <div v-if="people.type != 3" class="mt-1">
         <SearchSelector
          :key="$route.name"
          :selected="people.unconfiguredUsersOutcome"
          :searchData="people.type === '0' ? users : people.type === '1' ? queues : svi"
          @addElement="selectUnconfiguredUsersOutcome($event[0])"
         />
        </div>

        <div v-else class="flex-auto">
         <div class="relative mt-1 rounded-md shadow-sm">
          <input
           v-model="people.unconfiguredUsersOutcome"
           @keyup="
            /^\d+$/.test(people.unconfiguredUsersOutcome.split(' ').join(''))
             ? (errorPhoneNumberFormat = false)
             : (errorPhoneNumberFormat = true)
           "
           type="tel"
           name="nonAnsweringDestination"
           id="nonAnsweringDestination"
           minlength="10"
           placeholder="01 12 34 56 78"
           :class="[
            errorPhoneNumberFormat && people.unconfiguredUsersOutcome
             ? 'border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500'
             : 'border-gray-300',
            'block w-full h-10 rounded-md sm:text-sm',
           ]"
          />
          <div
           v-if="errorPhoneNumberFormat && people.unconfiguredUsersOutcome"
           class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
          >
           <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>
         </div>
         <p
          v-if="errorPhoneNumberFormat && people.unconfiguredUsersOutcome"
          class="ml-1 text-sm text-red-600"
         >
          Le numéro de téléphone doit être au format 01 98 76 54 32.
         </p>
        </div>
       </div>
       <p v-if="errorOnUnconfiguredUsersOutcome" class="ml-1 text-sm text-red-600 flex gap-2 mt-1">
        {{ $t("unconfiguredUsersOutcome") }}.
        <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
       </p>
      </div>
     </div>
    </div>
   </div>

   <div class="flex justify-end" ref="userForm">
    <button
     type="submit"
     @click="nextStep = false"
     class="capitalize ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white body__header--button"
     :class="{ 'animate-bounce': bounceButton === 'userForm' && unsavedChangesBool }"
    >
     {{ $t("save") }}
    </button>
    <button
     type="submit"
     @click="nextStep = true"
     class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white body__button"
    >
     {{ $t("nextStep") }}
    </button>
   </div>
  </form>
 </div>
 <ask-confirmation-dialog ref="askConfirmationDialog"></ask-confirmation-dialog>
</template>

<script>
import axios from "axios";
import {
 ExclamationIcon,
 ExclamationCircleIcon,
 InformationCircleIcon,
 PlusIcon,
} from "@heroicons/vue/solid";

import AskConfirmationDialog from "../../components/AskConfirmationDialog.vue";
import CollectAddUsers from "./CollectAddUsers.vue";
import CollectImportUsers from "./CollectImportUsers.vue";
import SearchExtensionsGroup from "./SearchExtensionsGroup.vue";
import SearchSelector from "../SearchSelector.vue";

const account = localStorage.getItem("account");

export default {
 name: "CollectUsers",
 props: [
  "contentData",
  "companyAddress",
  "fileId",
  "navIdx",
  "dependencies",
  "dependenciesToBeCleared",
  "phoneData",
  "customerFileId",
  "createdSda",
  "search",
  "bounceButton",
  "unsavedChangesBool",
  "elementRef",
  "users",
  "queues",
  "svi",
  "customerFileId",
  "sdaTown",
  "collectType",
 ],
 components: {
  AskConfirmationDialog,
  CollectAddUsers,
  CollectImportUsers,
  ExclamationIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
  PlusIcon,
  SearchExtensionsGroup,
  SearchSelector,
 },

 data() {
  return {
   account,
   createdExtensions: [],
   errorPhoneNumberFormat: false,
   errorOnUnconfiguredUsersOutcome: false,
   elementNumberEdited: undefined,
   isEdited: false,
   nextStep: false,
   newUsers: [],
   people: { unconfiguredUsersOutcome: "", users: [], type: "0" },
   previousUnconfiguredUsersOutcome: "",
   showForm: false,
   stepName: "users",
   showImportForm: false,
   thead: ["extension", "sda", "prenom", "nom", "email", "formation"],
   tableHeaders: [
    { name: "extension" },
    { name: "sdaLinked" },
    { name: "firstName" },
    { name: "lastName" },
    { name: "email" },
    { name: "formation" },
   ],
   unconfiguredUsersOutcome: "",
   userChosen: {},
   userIdx: null,
   visible: false,
   page: 1,
   rows: 10,
   selectedRows: null,
  };
 },

 methods: {
  getData() {
   let keys = Object.keys(this.contentData);
   if (keys.includes(this.stepName)) {
    this.people.users = this.contentData[this.stepName].users;

    this.people.unconfiguredUsersOutcome = this.contentData[this.stepName].unconfiguredUsersOutcome;
    this.previousUnconfiguredUsersOutcome =
     this.contentData[this.stepName].unconfiguredUsersOutcome;

    if (this.people.users) {
     this.people.users.forEach((user) => {
      this.createdExtensions.push(user.userSettings.internalNumber);
     });
    }
   }
  },
  selectUnconfiguredUsersOutcome(user) {
   this.people.unconfiguredUsersOutcome = user;
  },
  postCollect(msg) {
   this.errorOnUnconfiguredUsersOutcome = false;

   if (this.people.unconfiguredUsersOutcome) {
    let content = this.contentData;
    content[this.stepName] = { ...this.people };
    content = JSON.stringify(content);
    const options = {
     method: "PUT",
     url: `${this.$cookie.getCookie("API")}/api/v1/informationCollections/${
      this.fileId
     }?customerAccount=${this.account}`,
     data: {
      content: `${content}`,
     },
     headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
     },
    };

    axios
     .request(options)
     .then((res) => {
      let nextFormIndex = parseInt(this.$route.params.formIndex) + 1;
      this.$emit("activeBanner");
      this.$emit("success", true);
      this.$emit("successMsg", msg);
      if (this.nextStep) {
       window.scrollTo({
        top: 0,
        behavior: "smooth",
       });
       if (window.pageYOffset !== 0) {
        window.onscroll = () => {
         if (window.pageYOffset === 0) {
          window.onscroll = null;
          // Perform any additional actions here
          this.$router.push(
           `/customer-files/collect/${this.customerFileId}/${this.fileId}/${nextFormIndex}`
          );
         }
        };
       } else {
        this.$router.push(
         `/customer-files/collect/${this.customerFileId}/${this.fileId}/${nextFormIndex}`
        );
       }
      }
     })
     .catch(function (error) {
      this.errorHandling(error);
      this.$emit("activeBanner");
      this.$emit("success", false);
      this.$emit("successMsg", this.errorDataResponse);
     });
   } else {
    this.errorOnUnconfiguredUsersOutcome = true;
   }
  },
  sendFormFromNew(...args) {
   const form = args[0];
   const sdaLeft = args[1];
   const msg = args[2];

   this.contentData["numbers"].sdaUsed.sdaLeft = sdaLeft;
   let content = this.contentData;
   if (content[this.stepName] && content[this.stepName].users) {
    content[this.stepName].users.push(form);
   } else {
    content[this.stepName] = {
     unconfiguredUsersOutcome: this.people.unconfiguredUsersOutcome,
     users: [],
    };
    content[this.stepName].users.push(form);
   }

   content = JSON.stringify(content);
   const options = {
    method: "PUT",
    url: `${this.$cookie.getCookie("API")}/api/v1/informationCollections/${
     this.fileId
    }?customerAccount=${this.account}`,
    data: {
     content: `${content}`,
    },
    headers: {
     "Content-Type": "application/json",
     Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
   };

   axios
    .request(options)
    .then((res) => {
     this.$emit("activeBanner");
     this.$emit("success", true);
     this.$emit("successMsg", msg);
    })
    .catch(function (error) {
     this.errorHandling(error);
     this.$emit("activeBanner");
     this.$emit("success", false);
     this.$emit("successMsg", this.errorDataResponse);
    });
  },
  sendFormFromImport(...args) {
   const form = args[0];
   const sdaLeft = args[1];
   const msg = args[2];
   this.contentData["numbers"].sdaUsed.sdaLeft = sdaLeft;

   let content = this.contentData;
   if (content[this.stepName] && content[this.stepName].users) {
    content[this.stepName].users = form;
    content[this.stepName].unconfiguredUsersOutcome = "";
   } else {
    content[this.stepName] = {
     unconfiguredUsersOutcome: "",
     users: form,
    };
   }

   content = JSON.stringify(content);
   const options = {
    method: "PUT",
    url: `${this.$cookie.getCookie("API")}/api/v1/informationCollections/${
     this.fileId
    }?customerAccount=${this.account}`,
    data: {
     content: `${content}`,
    },
    headers: {
     "Content-Type": "application/json",
     Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
   };

   axios
    .request(options)
    .then((res) => {
     this.$emit("activeBanner");
     this.$emit("success", true);
     this.$emit("successMsg", msg);
    })
    .catch(function (error) {
     this.errorHandling(error);
     this.$emit("activeBanner");
     this.$emit("success", false);
     this.$emit("successMsg", this.errorDataResponse);
    });
  },
  editForm(...args) {
   const newForm = args[0];
   const sdaLeft = args[1];
   const msg = args[2];

   let depententKeys = this.dependenciesToBeCleared(this.stepName);
   this.contentData["numbers"].sdaUsed.sdaLeft = sdaLeft;

   this.people.users[this.userIdx] = newForm;

   let content = this.contentData;
   content[this.stepName].users = [...this.people.users];
   if (this.elementNumberEdited) {
    content = JSON.stringify(
     this.checkForElementInDependenciesEdit(
      content,
      depententKeys,
      this.elementNumberEdited,
      this.userIdx
     )
    );
   } else {
    content = JSON.stringify(content);
   }

   const options = {
    method: "PUT",
    url: `${this.$cookie.getCookie("API")}/api/v1/informationCollections/${
     this.fileId
    }?customerAccount=${this.account}`,
    data: {
     content: `${content}`,
    },
    headers: {
     "Content-Type": "application/json",
     Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
   };

   axios
    .request(options)
    .then((res) => {
     this.$emit("activeBanner");
     this.$emit("success", true);
     this.$emit("successMsg", msg);
    })
    .catch(function (error) {
     this.errorHandling(error);
     this.$emit("activeBanner");
     this.$emit("success", false);
     this.$emit("successMsg", this.errorDataResponse);
    });
  },
  async deleteUser(
   idx,
   confirmationMessage,
   title,
   msg,
   confirmButton,
   goBack,
   userExtensionToDelete
  ) {
   let depententKeys = this.dependenciesToBeCleared(this.stepName);
   const r = await this.$refs.askConfirmationDialog.show({
    title: title,
    message: confirmationMessage,
    okButton: confirmButton,
    cancelButton: goBack,
    action: "delete",
   });
   if (r) {
    var content = this.contentData;
    content[this.stepName].users = this.people.users;
    if (
     this.checkForElementInDependencies(content, depententKeys, userExtensionToDelete)
      .hasElementArray.length <= 0
    ) {
     if (this.people.users[idx].userSettings.sdaNumber.length > 0) {
      let sdaLeft = content["numbers"].sdaUsed.sdaLeft;
      let sdaMax = content["numbers"].sdaUsed.sdaMax;
      let numberSdaUsed = this.people.users[idx].userSettings.sdaNumber.length;
      let sdaDiff = parseInt(sdaLeft) + parseInt(numberSdaUsed);

      sdaLeft < sdaMax
       ? (content["numbers"].sdaUsed.sdaLeft = sdaDiff)
       : (content["numbers"].sdaUsed.sdaLeft = sdaMax);
     }
     this.people.users.splice(idx, 1);
     content = JSON.stringify(
      this.checkForElementInDependencies(content, depententKeys, userExtensionToDelete).content
     );
     const options = {
      method: "PUT",
      url: `${this.$cookie.getCookie("API")}/api/v1/informationCollections/${
       this.fileId
      }?customerAccount=${this.account}`,
      data: {
       content: `${content}`,
      },
      headers: {
       "Content-Type": "application/json",
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
     };
     axios
      .request(options)
      .then((response) => {
       this.$emit("activeBanner");
       this.$emit("success", true);
       this.$emit("successMsg", msg);
      })
      .catch((error) => {
       console.error(error);
       this.errorHandling(error);
      });
    } else {
     let elementBeingUsed = this.checkForElementInDependencies(
      content,
      depententKeys,
      userExtensionToDelete
     ).hasElementArray;
     let elementBeingUsedString = "";
     for (let index = 0; index < elementBeingUsed.length; index++) {
      const element = this.collectForms()[elementBeingUsed[index]];
      elementBeingUsedString = elementBeingUsedString + " " + element;
      if (elementBeingUsed.length > 1 && index < elementBeingUsed.length - 1) {
       elementBeingUsedString = elementBeingUsedString + ",";
      }
     }
     this.$emit("activeBanner");
     this.$emit("success", false);
     this.$emit("successMsg", this.$t("extensionBeingUsed", { forms: elementBeingUsedString }));
    }
   }
  },
  isUsedInDependencies(idx) {
   let isUsed = false;
   let userNumber = this.people.users[idx].userSettings.internalNumber;

   if (this.contentData && this.contentData["callQueue"]) {
    if (
     this.contentData["callQueue"].associateNumber === userNumber ||
     this.contentData["callQueue"].participants.includes(userNumber)
    ) {
     isUsed = true;
    }
   }

   return isUsed;
  },
  userTraining(training) {
   return training.user && training.admin
    ? "admin + user"
    : training.admin
    ? "admin"
    : training.user
    ? "user"
    : "none";
  },
  nextPage() {
   if (this.page < this.pagination().pages) {
    this.page = parseInt(this.page) + 1;
   } else {
    this.page = this.page;
   }
  },
  previousPage() {
   if (this.page > 1) {
    this.page = parseInt(this.page) - 1;
   } else {
    this.page = this.page;
   }
  },
  getPageLength() {
   if (this.page === this.pagination().pages) {
    return this.people.users.length;
   } else {
    return this.rows * this.page;
   }
  },
  pagination() {
   if (this.selectedRows) {
    this.rows = this.selectedRows;
   }
   let trimStart = (this.page - 1) * parseInt(this.rows);
   let trimEnd = trimStart + parseInt(this.rows);

   let trimmedData = this.people.users.slice(trimStart, trimEnd);

   let pages = Math.ceil(this.people.users.length / this.rows);

   return {
    querySet: trimmedData,
    pages: pages,
   };
  },
 },
 mounted() {
  this.getData();
 },
 computed: {
  filteredData() {
   return this.people.users.filter((res) => {
    if (this.search) {
     return (
      res.userSettings.firstName.toLowerCase().includes(this.search.toLowerCase()) ||
      res.userSettings.lastName.toLowerCase().includes(this.search.toLowerCase()) ||
      res.userSettings.internalNumber == this.search ||
      res.userSettings.email.toLowerCase().includes(this.search.toLowerCase())
     );
    } else {
     return 1;
    }
   });
  },
 },
 watch: {
  elementRef: function (ref) {
   if (ref) {
    const el = this.$refs.userForm;
    el.scrollIntoView({ behavior: "smooth", block: "end" });
   }
  },
  "people.unconfiguredUsersOutcome": {
   handler: function (arr) {
    JSON.stringify(this.previousUnconfiguredUsersOutcome) !== JSON.stringify(arr)
     ? this.$emit("unsavedChanges", true, "userForm")
     : this.$emit("unsavedChanges", false, "");
   },
   deep: true,
  },
 },
};
</script>
<style>
.wrapper {
 transition: 0.3s;
 width: 35px;
}
.wrapper:hover {
 transition: 0.5s;
 width: 110px;
}
/* #slide {
  position: absolute;
  left: -100px;
  margin-left: 10px;
  margin-top: 10px;
  height: 100%;
  width: 100%;
  transition: 1s;
}

#slide-hover {
  transition: 1s;
  left: 0;
} */
</style>
