<template>
 <div class="bg-white mx-5 px-4 py-5 sm:rounded-lg sm:p-6 shadow">
  <div class="sm:flex sm:items-center">
   <div class="sm:flex-auto">
    <h3 class="text-lg font-medium leading-6 text-gray-900">
     {{ $t("callqueues") }}
    </h3>
    <p class="mt-1 text-sm text-gray-500">
     {{ $t("waitingQueuesDescription") }}
    </p>
   </div>
   <div class="mt-0">
    <div class="flex justify-center">
     <button
      @click="
       (showForm = !showForm), (editIdx = undefined), (editData = undefined), (isEdit = false)
      "
      type="button"
      class="inline-flex items-center p-1.5 ml-5 border border-transparent shadow-sm text-sm font-medium rounded-md text-white body__header--button focus:outline-none encom_ring_color"
     >
      <PlusIcon class="h-5 w-5" aria-hidden="true" />
     </button>
    </div>
   </div>
  </div>
  <div v-if="showForm || isEdit">
   <CollectCallQueueFormPopup
    ref="form"
    :key="editIdx"
    :editIdx="editIdx"
    :users="users"
    :queuesNames="callqueuesNames"
    :contentData="contentData"
    :isEdit="isEdit"
    :showForm="showForm"
    :queues="queues"
    :svi="svi"
    :sdaTown="sdaTown"
    :editData="editData"
    :createdSda="createdSda"
    :fileId="fileId"
    :bounceButton="bounceButton"
    :unsavedChangesBool="unsavedChangesBool"
    :elementRef="elementRef"
    :collectType="collectType"
    @closeForm="(showForm = false), (isEdit = false), this.$emit('unsavedChanges', false, '')"
    @submitForm="(...args) => submitForm(...args)"
    @callqueue-form="(...args) => this.$emit('callqueueForm', ...args)"
    @unsaved-changes="(...args) => $emit('unsavedChanges', ...args)"
    @sda-left="currentSdaLeft = $event"
    @element-form="elementForm = $event"
    @update-sda-town="this.$emit('updateSdaTown', $event)"
    @remove-sda-town="(...args) => $emit('removeSdaTown', ...args)"
   />
  </div>
  <div v-show="callqueueListToAdd.length > 0">
   <collect-call-queue-table
    :tablePaginatedData="filteredData"
    @editForm="openEditForm($event), (isEdit = true)"
    @deleteItem="deleteItem($event)"
    :contentData="contentData"
   />
  </div>
 </div>

 <div class="bg-white mt-5 mx-5 px-4 py-2 sm:rounded-lg sm:px-6 sm:py-4 shadow">
  <form
   class="space-y-6"
   @submit.prevent="sendForm(this.$t('informationSaved'), null, this.currentSdaLeft)"
   method="POST"
  >
   <div class="md:grid md:grid-cols-5 md:gap-8">
    <div class="md:col-span-2">
     <h3 class="text-lg font-medium leading-6 text-gray-900">
      {{ $t("comment") }}
     </h3>

     <p class="mt-1 text-sm font-medium text-gray-700">{{ $t("callQueueAndSviComment") }}.</p>
    </div>
    <div class="md:col-span-2 md:col-start-3 md:mt-0">
     <label for="comment" class="block text-sm font-medium leading-6 text-gray-900">
      {{ $t("addYourComment") }}
     </label>
     <div class="mt-1">
      <textarea
       v-model="otherComment"
       rows="4"
       name="comment"
       id="comment"
       class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
      />
     </div>
    </div>
   </div>

   <div class="flex justify-end">
    <button
     type="submit"
     class="capitalize ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white body__header--button"
    >
     {{ $t("save") }}
    </button>
   </div>
  </form>
 </div>

 <ask-confirmation-dialog ref="askConfirmationDialog"> </ask-confirmation-dialog>
</template>

<script>
import AskConfirmationDialog from "../AskConfirmationDialog.vue";
import axios from "axios";
import CollectCallQueueFormPopup from "./CollectCallQueueFormPopup.vue";
import CollectCallQueueTable from "./CollectCallQueueTable.vue";
import { ExclamationIcon, PlusSmIcon, PlusIcon, InformationCircleIcon } from "@heroicons/vue/solid";
import { mapGetters } from "vuex";

const callqueueListToAdd = [];

export default {
 props: [
  "contentData",
  "fileId",
  "users",
  "dependenciesToBeCleared",
  "queues",
  "cancelPopUpCloseForm",
  "createdSda",
  "search",
  "sdaTown",
  "bounceButton",
  "unsavedChangesBool",
  "elementRef",
  "svi",
  "sdaLeft",
  "collectType",
 ],
 components: {
  InformationCircleIcon,
  CollectCallQueueTable,
  PlusSmIcon,
  PlusIcon,
  CollectCallQueueFormPopup,
  AskConfirmationDialog,
 },
 data() {
  return {
   callqueueListToAdd,
   editData: undefined,
   editIdx: null,
   formActive: false,
   currentSdaLeft: null,
   elementForm: {},
   isEdit: false,
   nextStep: false,
   otherComment: "",
   showForm: false,
   stepName: "callQueue",
  };
 },
 methods: {
  getData() {
   if (this.contentData) {
    let keys = Object.keys(this.contentData);
    if (keys.includes(this.stepName)) {
     this.callqueueListToAdd = this.contentData[this.stepName].data;
     this.otherComment = this.contentData[this.stepName].otherComment;
    }
   }
  },
  sendForm(msg, action, sdaLeft) {
   let stepContent = [];
   let content = this.contentData;

   if (action === "POST" || action === "PUT" || action === "DELETE") {
    stepContent = [...this.callqueueListToAdd];
   } else {
    if (this.callqueueListToAdd.length === 0) {
     if (Object.keys(this.elementForm).length > 0) {
      stepContent.push(this.elementForm);
     }
    } else if (this.editIdx !== null) {
     stepContent = [...this.callqueueListToAdd];
     stepContent.splice(this.editIdx, 1, this.elementForm);
    } else {
     stepContent = [...this.callqueueListToAdd];
     if (Object.keys(this.elementForm).length > 0) {
      stepContent.push(this.elementForm);
     }
    }
   }
   this.contentData["numbers"].sdaUsed.sdaLeft = sdaLeft;
   content[this.stepName] = { otherComment: this.otherComment, data: stepContent };
   if (content[this.stepName].otherComment === "" && content[this.stepName].data.length === 0) {
    delete content[this.stepName];
   }
   content = JSON.stringify(content);
   const options = {
    method: "PUT",
    url: `${this.$cookie.getCookie("API")}/api/v1/informationCollections/${
     this.fileId
    }?customerAccount=${this.account}`,
    data: {
     content: `${content}`,
    },
    headers: {
     "Content-Type": "application/json",
     Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
   };

   axios
    .request(options)
    .then((res) => {
     this.editIdx = null;

     this.$emit("activeBanner");
     this.$emit("success", true);
     this.$emit("successMsg", msg);
    })
    .catch(function (error) {
     this.errorHandling(error);
     this.$emit("activeBanner");
     this.$emit("success", false);
     this.$emit("successMsg", this.errorDataResponse);
    });
  },
  openEditForm(idx) {
   if (this.callqueueListToAdd.length > 0) {
    this.editData = this.callqueueListToAdd[idx];

    this.editIdx = idx;
   }
   this.showForm = true;
  },
  async deleteItem(idx) {
   let depententKeys = this.dependenciesToBeCleared(this.stepName);
   let msg = this.$t("callQueueDeletedSuccessfully");

   let title = this.$t("deleteMessageTitle");
   let message = this.$t("deleteMessage");
   let confirmButton = this.$t("delete");
   let goBack = this.$t("cancel");
   if (this.callqueueListToAdd[idx].associateNumber.length > 0) {
    let sdaLeft = this.contentData["numbers"].sdaUsed.sdaLeft;
    let numberSdaUsed = this.callqueueListToAdd[idx].associateNumber.length;
    var sdaDiff = parseInt(sdaLeft) + parseInt(numberSdaUsed);
   } else {
    sdaDiff = this.contentData["numbers"].sdaUsed.sdaLeft;
   }
   const r = await this.$refs.askConfirmationDialog.show({
    title: title,
    message: message,
    okButton: confirmButton,
    cancelButton: goBack,
    action: "delete",
   });
   if (r) {
    this.callqueueListToAdd.splice(idx, 1);
    this.sendForm(msg, "DELETE", sdaDiff);
   }
  },
  submitForm(...args) {
   const form = args[0];
   const sdaLeft = args[1];
   const msg = args[2];
   if (this.editIdx >= 0) {
    this.callqueueListToAdd.splice(this.editIdx, 1, form);
    this.sendForm(msg, "PUT", sdaLeft);
   } else {
    this.callqueueListToAdd.push(form);
    this.sendForm(msg, "POST", sdaLeft);
   }
   this.showForm = false;
  },

  clearForm() {
   for (let index = 0; index < this.modules.length; index++) {
    const element = this.modules[index];
    element.active = 0;
   }
  },
 },
 mounted() {
  this.getData();
  this.currentSdaLeft = this.sdaLeft;
 },

 watch: {
  cancelPopUpCloseForm: function (val) {
   if (val) {
    this.showForm = false;
    this.isEdit = false;
   }
  },
 },
 computed: {
  ...mapGetters(["account"]),
  callqueuesNames() {
   let queues = [];

   if (this.contentData && this.contentData.callQueue && this.contentData?.callQueue?.data) {
    this.contentData.callQueue.data.forEach((element) => {
     queues.push(element.name);
    });
   }
   return queues;
  },
  filteredData() {
   return this.callqueueListToAdd.filter((res) => {
    if (this.search) {
     return res.name.toLowerCase().includes(this.search.toLowerCase());
    } else {
     return 1;
    }
   });
  },
 },
};
</script>

<style></style>
